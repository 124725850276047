import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { stringToNgbDate } from 'src/app/helpers/formatter';
import { InputFieldType } from '../custom-fields-form/input-field-type.enum';

@Component({
  selector: 'app-demo-generate-form',
  templateUrl: './demo-generate-form.component.html',
  styleUrls: ['./demo-generate-form.component.scss']
})
export class DemoGenerateFormComponent implements OnInit {
  @ViewChild('formElem') formElem;
  @Output() swipeLeft = new EventEmitter<string>();
  @Output() swipeRight = new EventEmitter<string>();
  
  content: any;
  current = moment().format('DD-MM-YYYY');
  // current = moment();
  textAreaMax: number = 100;
  nameMaxLength: number = 70;

  // Form config & data
  formParam: any = {};
  formInput: any = {};

  canEdit: boolean = false;
  clipboardTooltip: string = 'Copy';

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.clipboardTooltip = 'Copy';
    this.initContent();
    this.initFormElem();
    this.setFormOptions();
  }
  
  //------ UI Function ------//
  onSwipeLeft() {
    // for works.ts
    this.resetClickCopy();
  }

  onSwipeRight() {
    // for works.ts
    this.resetClickCopy();
  }

  onClickCopy(){
    this.clipboardTooltip = 'Copied';
    setTimeout(() => {
      this.resetClickCopy();
    }, 10000)
  }

  resetClickCopy(){
    this.clipboardTooltip = 'Copy';
  }

  setFormOptions(){
    this.formParam.fieldOptions['formGender'] = [
      {
        id: 'female',
        key: 'female',
        value: 'f',
        label: 'Female'
      },
      {
        id: 'male',
        key: 'male',
        value: 'm',
        label: 'Male'
      },
      {
        id: 'other',
        key: 'other',
        value: 'other',
        label: 'Other'
      }
    ];

    this.formParam.fieldOptions['formHobby'] = [
      {
        id: 'movie',
        key: 'movie',
        value: false,
        label: 'Movie'
      },
      {
        id: 'sports',
        key: 'sports',
        value: false,
        label: 'Sports'
      },
      {
        id: 'reading',
        key: 'reading',
        value: false,
        label: 'Reading'
      }
    ];

    this.http.get('https://trial.mobiscroll.com/content/countries.json').subscribe((response: any) => {
        const countries = [];
        for (let i = 0; i < response.length; ++i) {
            const country = response[i];
            countries.push({ 
              id: country.value,
              key: country.value,
              label: country.text, 
              value: country.value 
            });
        }
        this.formParam.fieldOptions['formCountry'] = countries;
    });
  }

  onClickSubmit = () => {
    this.setShowError(true);
    if(this.validateForm()){
      this.formParam.isEditMode = false;
      this.canEdit = false;
      this.formParam.header.hideBtn = this.canEdit;
    }
  }

  onClickEdit = () => {
    this.formParam.isEditMode = true;
    this.canEdit = true;
    this.formParam.header.hideBtn = this.canEdit;
  }

  onChangeName = () => {
    if(this.formInput.formName.length > this.nameMaxLength){
      this.setShowError(true);
      this.setFieldError('formName', true);
      this.setFieldErrorMsg('formName', 'Max length of name is '+this.nameMaxLength+' characters');

    }else{
      this.setFieldError('formName', false);
    }
  }

  onChangeGender = () => {
    this.setFieldError('formGender', false);
  }

  onChangeBirthDate = () => {
    this.setFieldError('formDate', false);
    // if(this.formInput.formDate > this.current || !this.formInput.formDate){
    if(moment(this.formInput.formDate, "DD-MM-YYYY").isAfter(this.current) || !this.formInput.formDate) {
      this.setShowError(true);
      this.setFieldError('formDate', true);
      this.setFieldErrorMsg('formDate', 'Date of birth cannot be a future date');
    }else{
      this.setFieldError('formDate', false);
    }
  }

  onChangeHobby = () => {
    this.setFieldError('formHobby', false);
  }

  onChangeCountry = () => {
    this.setFieldError('formCountry', false);
  }

  onChangeMsg = () => {
    let hasBadWords = this.formElem.checkBadWords(this.formInput.formMessage);
    if( hasBadWords || this.formInput.formMessage.length > this.textAreaMax){
      if(hasBadWords){
        this.setFieldError('formMessage', true);
        this.setFieldErrorMsg('formMessage', 'Please do not input bad words');  
      }
      if(this.formInput.formMessage.length > this.textAreaMax){
        this.setFieldError('formMessage', true);
        this.setFieldErrorMsg('formMessage', 'Message cannot exceed '+this.textAreaMax+' characters');
      }
    }else{
      this.setFieldError('formMessage', false);
    }
  }

  validateForm(){
    this.formParam.fieldList.forEach(field => {
      if(field.mandatory){
        if(field.type === InputFieldType.checkbox){
          let checkedCount = Object.values(this.formInput[field.id]).filter(value => value === true).length;
          (checkedCount == 0 || field['isError']) ? this.setFieldError((field.id), true) : this.setFieldError((field.id), false);
        }else{
          (!this.formInput[field.id] || field['isError']) ? this.setFieldError((field.id), true) : this.setFieldError((field.id), false);
        }
      }else{
        field['isError'] ? this.setFieldError((field.id), true) : this.setFieldError((field.id), false);
      }
    });
    
    if(this.formParam.fieldList.filter(field => field.isError).length > 0 ){
      return false;
    }else{
      return true;
    }
  }

  setFieldError(fieldId, isError){
    this.formParam.fieldList.find(field => field.id == fieldId).isError = isError;
    if (!isError) { this.setFieldErrorMsg(fieldId, undefined); }
  }

  setFieldErrorMsg(fieldId, msg){
    this.formParam.fieldList.find(field => field.id == fieldId).errorMsg = msg;
  }

  setShowError(isShow){
    this.formParam.showFieldsError = isShow;
  }

  //------ Init Component ------//
  private initContent(){
    this.content = {
      id: 'demo-form',
      order: '0038',
      title: 'Demo of Angular Form',
      brand: '',
      location: '',
      technologies: [ 'Angular 9', 'ng-select', 'ngb-date-picker' ],
      challenges: [
        'Design warning message that is matched with UX principle if user input invalid data',
        'Filter bad words on message field'
      ],
      description: `This form is a re-usable component, can be crated easily by setting config (shown as picture 1). 
                    Validate the data when user finished input (onChange), and will check again before submit. 
                    User can also edit the information after submitted.
                    `,
      isYoutube: false,
      isAnimated: false,
    }
  }

  initFormElem(){
    this.formParam.showFieldsError = false;
    this.formParam.isEditMode = true;
    this.formParam.displayFieldAsRow = true;
    this.formParam.instruction = '';
    this.formParam.fieldOptions = { };
    this.formParam.onChangeBtn = [
      { id: 'submit-btn', icon:['fas','paper-plane'], iconClass:'pe-2 font-l', customClass: 'brand-blue',  onClick: this.onClickSubmit, text: 'Submit' }
    ];

    this.formParam.header = {
      title: 'Form Demo',
      customClass: 'bold font-xl',
      hideBtn: true
    }
    this.formParam.headerBtn = [
      { id: 'edit-btn', text: 'Edit', icon : ['fas','pen'], iconClass: 'pe-2 font-l', type: 'button', onClick: this.onClickEdit }
    ];

    this.formParam.fieldList = [
      { 
        id: 'formName', 
        type: InputFieldType.text, 
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: true,
        isError: false,
        disabled: false,
        onChange: this.onChangeName,
        name: 'Name'
      },
      { 
        id: 'formGender', 
        type: InputFieldType.radio, 
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: true,
        isError: false,
        disabled: false,
        onChange: this.onChangeGender,
        onSearch: () => {},
        name: 'Gender'
      },
      { 
        id: 'formDate', 
        type: InputFieldType.date, 
        titleCustomClass: 'bold',
        placeholder: 'YYYY-MM-DD',
        mandatory: true,
        isError: false,
        disabled: false,
        maxDate: stringToNgbDate(this.current),
        onChange: this.onChangeBirthDate,
        onSearch: () => {},
        name: 'Date of Birth'
      },
      { 
        id: 'formHobby', 
        type: InputFieldType.checkbox, 
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: true,
        isError: false,
        disabled: false,
        onChange: this.onChangeHobby,
        name: 'Hobby'
      },
      { 
        id: 'formCountry', 
        type: InputFieldType.dropdown, 
        titleCustomClass: 'bold',
        placeholder: '',
        mandatory: true,
        isError: false,
        disabled: false,
        template: false,
        clearable: true,
        closeOnSelect: true,
        onChange: this.onChangeCountry,
        onSearch: () => {},
        onClear: () => {},
        scrollToEnd: () =>{},
        name: 'Country'
      },
      { 
        id: 'formMessage', 
        type: InputFieldType.textarea, 
        titleCustomClass: 'bold',
        placeholder: '',
        maxLength: 100,
        rowLength: 3,
        mandatory: false,
        isError: false,
        disabled: false,
        onChange: this.onChangeMsg,
        name: 'Message'
      },
    ];
  }
}
