import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-works',
  templateUrl: './works.component.html',
  styleUrls: ['./works.component.scss'],
  providers: [NgbCarouselConfig]
})
export class WorksComponent implements OnInit {
  @ViewChild('itemSlide') itemSlide: NgbCarousel;
  @ViewChild('vitaDemoElem') vitaDemoElem;
  @Input() workContent: any;

  isShowSlide: boolean = false;
  isPaused: string;
  isAnimated: boolean = true;
  clipboardTooltip: string = 'Copy';

  constructor(
    config: NgbCarouselConfig,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private _location: Location
    ) {
    // customize default values of carousels used by this component tree
    config.interval = 1000;
    config.wrap = true; //loop
    config.animation = false;
  }

  ngOnInit(): void {
    this.clipboardTooltip = 'Copy';
  }

  ngAfterViewInit() {
    this.itemSlide.pause();
  }

  public selectItem(itemId){
    this.itemSlide.select(itemId);
  }

  onClickClose(){
    this.hideSlide();
    this._location.go(""); //window.history.pushState is not working in Safari
    this.resetClickCopy();
  } 

  onClickCopy(){
    this.clipboardTooltip = 'Copied';
    setTimeout(() => {
      this.resetClickCopy();
    }, 10000)
  }

  resetClickCopy(){
    this.clipboardTooltip = 'Copy';
  }

  public hideSlide(){
    this.isShowSlide = false;
    this.resetClickCopy();
    if (isPlatformBrowser(this.platformId)) {
      document.body.classList.remove('overflow-hidden');
    }
  }

  public showSlide(){
    this.isShowSlide = true;
    this.resetClickCopy();
    if (isPlatformBrowser(this.platformId)) {
      document.body.classList.add('overflow-hidden');
    }
  }

  public playVita(){
    this.vitaDemoElem.playAnimation();
  }

  onPause(id:string){
    this.isPaused = id;
  }
  
  onContiue(id: string){
    if(this.isPaused == id){
      this.isPaused = undefined;
    }
  }

  onReplay(){
    this.isAnimated = false;
    setTimeout(() => {
      this.isAnimated = true;
    }, 200)
  }

  onSlide(event){
    if (isPlatformBrowser(this.platformId)) {
      document.getElementsByClassName('carousel-inner')[0].scrollTop = 0;
      // window.history.pushState("", event.current, "/id/" + event.current);
      this._location.go( "/id/" + event.current);
      this.resetClickCopy();
    }
  }

  onSwipeLeft(workContent, i) {
    this.itemSlide.next();
    this.resetClickCopy();

    let n:number = i + 1;
    let last:number = workContent.length - 1;
    if(n > last){
      this._location.go( "/id/" + workContent[0]?.id);
      // window.history.pushState("", workContent[0]?.id, "/id/" + workContent[0]?.id);
    }else{
      this._location.go( "/id/" + workContent[n]?.id);
      // window.history.pushState("", workContent[n]?.id, "/id/" + workContent[n]?.id);
    }
  }

  onSwipeRight(workContent, i) {
    this.itemSlide.prev();
    this.resetClickCopy();

    let n:number = i - 1;
    let last:number = workContent.length - 1;
    if(n < 0 ){
      this._location.go( "/id/" + workContent[last]?.id);
      // window.history.pushState("", workContent[last]?.id, "/id/" + workContent[last]?.id);
    }else{
      this._location.go( "/id/" + workContent[n]?.id);
      // window.history.pushState("", workContent[n]?.id, "/id/" + workContent[n]?.id);
    }
    
  }
}
