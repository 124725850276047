// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  google: {
    GA_TRACKING_ID: 'G-HCFDQL7HWH'
  },
  firebase: {
    apiKey: "AIzaSyC7Ek6QO3Qpe7PtMTOEGrq7TX-xkmn9qZ8",
    authDomain: "zoeshu-playground.firebaseapp.com",
    databaseURL: "https://zoeshu-playground-default-rtdb.firebaseio.com",
    projectId: "zoeshu-playground",
    storageBucket: "zoeshu-playground.appspot.com",
    messagingSenderId: "862897820684",
    appId: "1:862897820684:web:a0d02989584558482a8a29",
    measurementId: "G-XSTVNHP64C"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
