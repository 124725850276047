<div class="slide-content-wrap row mx-0 justify-content-center align-items-bottom px-5"
  (swiperight)="onSwipeRight()" (swipeleft)="onSwipeLeft()"
>
  <div class="custom-caption col-12 col-md-5 col-xl-4 white-7 mt-5 text-start p-3">
    <div *ngIf="content.brand">
      <span class="bold second-color">Brand: </span> <span class="c-text-shadow"> {{ content.brand }}</span>
    </div>
    <div *ngIf="content.location">
      <span class="bold second-color">Venue: </span> <span class="c-text-shadow"> {{ content.location }}</span>
    </div>
    <p [innerHtml]="content.description" class="mt-4 c-text-shadow">
            
    </p>
    <div *ngIf="content.technologies" class="mt-4 align-items-start d-block">
      <span class="bold second-color">Technologies: </span>
      <ul class="mb-0 c-text-shadow">
        <li *ngFor="let tech of content.technologies">
          {{ tech }}
        </li>
      </ul>
    </div>
    <div *ngIf="content.challenges" class="mt-4 align-items-start d-block">
      <span class="bold second-color">Challenges: </span>
      <ul class="c-text-shadow">
        <li *ngFor="let challenge of content.challenges">
          {{ challenge }}
        </li>
      </ul>
    </div>
    <div class="mt-4 align-items-start d-block">
      <span class="bold me-2 second-color">Share This Project: </span>
      <!-- <a target="_blank" href="https://zoeshu-playground.com/{{content.id}}">
        https://zoeshu-playground.com/{{content.id}}
      </a> -->
      <button *ngIf="true" class="custom-btn" 
        [cdkCopyToClipboard]="'https://zoeshu-playground.com/id/'+content.id" (click)="onClickCopy()"
      >
        <fa-icon [icon]="['far', 'copy']" class="pe-2 font-xl"></fa-icon>
        <span class="">{{clipboardTooltip}} URL</span>
      </button>
    </div>
    <a class="link-ins d-block text-center" 
      [href]="content.url" target="_blank" >
      <app-youtube-icon></app-youtube-icon>
    </a>
  </div>

  <div class="col-12 col-md-6 col-xl-7 mt-4 mt-md-5 demo-col mx-2">
    <div class="demo-wrapper pages p2 mx-auto" >
        <div class="throw-wrap">
        <div class="drag-area ">
          <div class="draggable " >
            <div class="pyro left">
              <div class="before"></div>
              <div class="after"></div>
            </div>
            <div class="pyro top">
              <div class="before"></div>
              <div class="after"></div>
            </div>
            <div class="pyro right">
              <div class="before"></div>
              <div class="after"></div>
            </div>
            <div class="pyro bottom">
              <div class="before"></div>
              <div class="after"></div>
            </div>

            <div class="firefly">
              <div class="glow"></div>
            </div>
            <img loading="lazy" class="item wing show" src="../../assets/img/lancome_fireworks/lancome.png" />
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="col-12 col-md-6 mt-4 px-md-2 px-0">
    <picture >
      <source type="image/webp" srcset="../../assets/img/lancome_firework.webp">
      <source type="image/jpeg" srcset="../../assets/img/lancome_firework.jpg">
      <img loading="lazy" src="../../assets/img/lancome_firework.jpg">
    </picture>
  </div>

  <div class="col-12 col-md-6 mt-4 px-md-2 px-0">
    <picture >
      <source type="image/webp" srcset="../../assets/img/lancome_firework_venue.webp">
      <source type="image/png" srcset="../../assets/img/lancome_firework_venue.png">
      <img loading="lazy" src="../../assets/img/lancome_firework_venue.png">
    </picture>
  </div>

  <div class="mt-4 text-center">
    <app-youtube-iframe [url]="content.url" class="mt-2"></app-youtube-iframe>
  </div>
</div>