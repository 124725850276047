import { Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { WebInfo } from './entity/enum/web-info.enum';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { WINDOW } from './services/window.providers';
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = WebInfo.websiteName;
  
  constructor(
    private titleService: Title,
    private meta: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(WINDOW) private window: Window | null
  ) {}

  @HostListener('window:keyup', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if (event.code === 'F12') {
      gtag('event', 'F12');
    }
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    gtag('event', 'right_click');
  }

  ngOnInit(){
    this.getPageType();
  }

  setTitle(title: string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ property: 'og:site_name', content: title });
    this.meta.updateTag({ name: 'twitter:title', content: title });
  }

  setDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
    this.meta.updateTag({ property: 'og:description', content: desc });
    this.meta.updateTag({ name: 'twitter:description', content: desc });
  }

  setKeywords(keywords: string) {
    this.meta.updateTag({ name: 'keywords', content: keywords })
  }

  setImg(img: string) {
    this.meta.updateTag({ property: 'og:image', content: img });
    this.meta.updateTag({ name: 'twitter:image', content: img });
  }

  setImgSize(width, height) {
    this.meta.updateTag({ property: 'og:image:width', content: width });
    this.meta.updateTag({ property: 'og:image:height', content: height });
  }

  setUrl(url: string) {
    this.meta.updateTag({ property: 'og:url', content: url });
    document.getElementById('canonical').setAttribute('href', url);
  }

  setFavIco(url:string){
    document.getElementById('favico').setAttribute('href', url);
  }

  getPageType(){
    if (isPlatformBrowser(this.platformId) && this.window) {
      let route = window.location.pathname;
      if(route){
        if(route == '/' || route == '/portfolio' ){
          this.setTitle('Zoe Shu\'s Portfolio');
          this.setUrl('https://zoeshu-playground.com');
          this.setDescription('a Web Developer from Hong Kong, created over 30 websites, passionate about frontend development and love to learn new things about programming');
          this.setKeywords('Zoe Shu, Portfolio, Angular9, JavaScript, HTML5, CSS, Hong Kong, Web Developer');
          this.setImg('https://zoeshu-playground.com/assets/img/web/ogimg.png');
          this.setImgSize(1200, 630);
          this.setFavIco('favicon.ico');

        }else if(route == '/animal-crossing' || route == '/animal-crossing/cooking'){
          this.setTitle('動物森友會 - 料理成本及賣出價');
          this.setUrl('https://zoeshu-playground.com/animal-crossing/cooking');
          this.setDescription('動森料理售價比較！料理成本即是料理材料賣出後的價錢總和，有些料理是材料比較能賣錢。最賺錢的料理是...');
          this.setKeywords('動物森友會, 動森2.0更新, 動森攻略, 快樂家園, 動森料理, 141種食譜, 售價, 賣價, 收購價, あつ森, 売価');
          this.setImg('https://zoeshu-playground.com/assets/animal-crossing/title.png');
          this.setImgSize(603, 294);
          this.setFavIco('/assets/animal-crossing/ac.ico');
        }
        else if(route == '/pokemon' || route == '/pokemon/types-table'){
          this.setTitle('Pokemon 朱/紫 - 屬性相剋篩選器');
          this.setUrl('https://zoeshu-playground.com/pokemon');
          this.setDescription('Pokemon攻略 - 屬性相剋組合');
          this.setKeywords('Pokemon,朱,紫,寶可夢,屬性,寵物小精靈,神奇寶貝,攻略');
          this.setImg('https://zoeshu-playground.com/assets/animal-crossing/title.png');
          this.setImgSize(603, 294);
          this.setFavIco('/assets/animal-crossing/ac.ico');
        } 
        else if(route == '/pokemon/get-room'){
          this.setTitle('Pokemon 朱/紫 - 6V Rooom');
          this.setUrl('https://zoeshu-playground.com/pokemon/get-room');
          this.setDescription('Pokemon攻略 - 6V Room');
          this.setKeywords('Pokemon,朱,紫,寶可夢,屬性,寵物小精靈,神奇寶貝,攻略');
          this.setImg('https://zoeshu-playground.com/assets/animal-crossing/title.png');
          this.setImgSize(603, 294);
          this.setFavIco('/assets/animal-crossing/ac.ico');
        } 
        else if(route == '/game'){
          this.setTitle('遊戲攻略');
          this.setUrl('https://zoeshu-playground.com/game');
          this.setDescription('遊戲攻略');
          this.setKeywords('動物森友會, Pokemon,朱,紫,寶可夢,屬性,寵物小精靈,神奇寶貝,攻略');
          this.setImg('https://zoeshu-playground.com/assets/animal-crossing/title.png');
          this.setImgSize(603, 294);
          this.setFavIco('/assets/animal-crossing/ac.ico');
        } 
        else if(route == 'bbq-game'){
          this.setTitle('BBQ Game');
          this.setUrl('https://zoeshu-playground.com/bbq-game');
          this.setDescription('Developed by Zoe Shu');
          this.setImg('https://zoeshu-playground.com/assets/img/web/ogimg.png');
          this.setFavIco('favicon.ico');
        }
      }
      
    }
  }
}
