<div class="profile-section section-container theme-bg" (scroll)="onScroll($event)" (window:resize)="onResize($event)">

  <div class="h-100 ">
    <div class="profile-wrap w-100 pb-5">
      <div class="name-wrap">
        <picture class="name with-shadow" >
          <source type="image/webp" srcset="../../assets/img/web/name_v5.webp">
          <source type="image/png" srcset="../../assets/img/web/name_v5.png">
          <img src="../../assets/img/web/name_v5.png">
        </picture>
        <picture class="name no-shadow"  >
          <source type="image/webp" srcset="../../assets/img/web/name_normal@3x.webp">
          <source type="image/png" srcset="../../assets/img/web/name_normal@3x.png">
          <img src="../../assets/img/web/name_normal@3x.png">
        </picture>
      </div>
      <div class="name-text-only second-color font-custom">
        This is Zoe
      </div>
      
      <app-contact #contactComponent class="d-block position-relative"></app-contact>
      <div class="introduction white-7 font-custom font-xl text-start second-color">
        <ul class="ps-0">
          <li>{{ currentYear - 2017 }} years experience in Web Development</li>
          <li>Passionate about programming</li>
          <li>Developed over 30 websites</li>
          <li>Showcasing all my work here</li>
        </ul>
        <div class="second-color font-custom text-uppercase font-xxl">Explore and enjoy!</div>
      </div>
    </div>

    <div class="work-item-wrap row mx-0 w-100" #workItemWrap>

      <ng-container *ngIf="workContent">

        <ng-container *ngFor="let item of workContent; let i = index;" >
          <div class="work-item p-2" (click)="onClickProject(item.id)">
            <ng-container *ngIf="!item.preview">
              <div class="work-item-icon no-preview font-custom second-color-bg " 
              [class]="'item-'+i" [title]="item.title" (click)="onClickDot(item.id)">
              <span>{{ item.title }}</span>
            </div>
            </ng-container>
            <ng-container *ngIf="item.preview">
              <div class="work-item-icon second-color-bg second-color" 
              [class]="'item-'+i" [style.backgroundImage]="'url('+ item.preview.path+'.'+item.preview.format +')'"
              [style.backgroundPositionY]="bgPosY"
              [title]="item.title" (click)="onClickDot(item.id)" ></div>
            </ng-container>
          </div>
          
        </ng-container>

        <div class="work-item"></div>
        <div class="work-item"></div>
        <div class="work-item"></div>
        <div class="work-item"></div>
        <div class="work-item"></div>
        <div class="work-item"></div>

      </ng-container>

    </div>
  </div>


  <app-works #worksComponent [workContent]="workContent"></app-works>

</div>