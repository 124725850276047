import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

export function ngbDateToString(date: NgbDateStruct | undefined): string | undefined {
  if(date && date.year && date.month && date.day){
    return date.year.toString() + date.month.toString().padStart(2, '0') + date.day.toString().padStart(2, '0');
  }
  return undefined;
}

export function stringToNgbDate (value: string | undefined): NgbDateStruct | undefined {
  const dateFormat = 'DD-MM-YYYY'; // Adjust this if your string format is different
  if (value) {
    const date = moment(value, dateFormat);
    if (date.isValid()) {
      return {
        day: +date.format("D"),
        month: +date.format("M"),
        year: +date.format("YYYY")
      };
    }
  }
  return undefined;
}
