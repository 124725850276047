import { InjectionToken, Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export const WINDOW = new InjectionToken<Window>('WindowToken');

@Injectable({ providedIn: 'root' })
export class WindowService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  get nativeWindow(): Window | null {
    return this.document.defaultView;
  }
}

export const windowProviders = [
  { provide: WINDOW, useFactory: windowFactory, deps: [WindowService] }
];

export function windowFactory(windowService: WindowService): Window | null {
  return windowService.nativeWindow;
}
