import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
  // { path: '', redirectTo: 'portfolio', pathMatch: 'full' },
  { path: '', children: [
    { path: '', component: HomeComponent },
  ]},
  { path: 'id', children: [
    { path: '', component: HomeComponent },
    { path: ':project', component: HomeComponent }
  ]},
  { path: 'portfolio', children: [
    { path: '', component: HomeComponent },
    { path: ':project', component: HomeComponent }
  ]},
  // { path: 'bbq-game', component: BeefGrillGameComponent},
  { path: 'game', loadChildren: () => import('./pages/game/game-home/game-home.module').then(m => m.GameHomeModule) },
  { path: 'animal-crossing', children: [
    { path: '',
      loadChildren: () => import('./pages/animal-crossing/home/home.module').then(m => m.HomeModule) 
    },
    { path: 'cooking', 
      loadChildren: () => import('./pages/animal-crossing/cooking/cooking.module').then(m => m.CookingModule) 
    },
  ]},
  { path: 'pokemon', children: [
    { path: 'types-table', 
      loadChildren: () => import('./pages/pokemon/pokemon-types/pokemon-types.module').then(m => m.PokemonTypesModule)  
    },
    { path: '', 
      loadChildren: () => import('./pages/pokemon/pokemon-types-rev/pokemon-types-rev.module').then(m => m.PokemonTypesRevModule) 
    },
    // { path: 'get-room', 
    //   loadChildren: () => import('./pages/pokemon/pokemon-get-room/pokemon-get-room.module').then(m => m.PokemonGetRoomModule) 
    // },
  ]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
