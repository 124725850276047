<div class="contact-section p-2">
  <div class="contact about-me" (click)="onClickLink('AboutMe')">
    <fa-icon [icon]="['fas', 'id-card']" class="primary-color font-xl" title="About Me" (click)="openAboutMe()"></fa-icon>
  </div>
  <div class="contact skill-set" (click)="onClickLink('SkillSet')">
    <fa-icon [icon]="['fas', 'code']" class="primary-color font-xl" title="My Skill Set" (click)="openSkillSet()"></fa-icon>
  </div>
  <div class="contact email">
    <a href="mailto:zoe.shu.wm@gmail.com" (click)="onClickLink('Email')">
      <fa-icon [icon]="['fas', 'envelope']" class="primary-color font-xl" title="Email Me"></fa-icon>
    </a>
  </div>
  <div class="contact linkedin">
    <a href="https://www.linkedin.com/in/zoe-shu/" target="_blank" (click)="onClickLink('LinkedIn')">
      <fa-icon [icon]="['fab', 'linkedin']" class="primary-color font-xl" title="LinkedIn"></fa-icon>
    </a>
  </div>
  <div class="contact github mb-0">
    <a href="https://github.com/zoe-shu" target="_blank" (click)="onClickLink('GitHub')">
      <fa-icon [icon]="['fab', 'github']" class="primary-color font-xl" title="GitHub"></fa-icon>
    </a>
  </div>
</div>

<div class="skill-set-section theme-bg " [class.show-window]="isOpenSkillSet" [class.hide-window]="!isOpenSkillSet">
  <div class="close-btn second-color font-xl px-3" (click)="closeSkillSet()">&#10006;</div>
  <div class="skill-wrap row mx-0 h-100 align-items-center">
    <div class="row mx-0 w-100 justify-content-center">
      <ul class="programming-skill col-12 col-md-auto white-7 font-custom second-color mt-3 ps-0">
        <div class="skill-title">Programming Languages <span>Years of exp.</span></div>
        <li>JavaScript <span>{{ currentYear - 2017 }}</span></li>
        <li>Node.js <span>2</span></li>
        <li>Vue.js, React.js <span>0.5</span></li>
        <li>Angular <span>{{ currentYear - 2019 }}</span></li>
        <li>Selenium (Automation Testing) <span>2</span></li>
        <li>Jasmine.js, Mocha.js <span>2</span></li>
        <li>jQuery <span>3</span></li>
        <li>PHP (Wordpress, Laravel) <span>2</span></li>
        <li>HTML5 <span>{{ currentYear - 2017 }}</span></li>
        <li>CSS3, SCSS, Sass, less <span>{{ currentYear - 2017 }}</span></li>
      </ul>
      <div class="col-12 col-md-1"></div>
      <ul class="other-skill col-12 col-md-auto white-7 font-custom second-color mt-3 ps-0">
        <div class="skill-title">Other Skill <span>Years of exp.</span></div>
        <li>MySQL <span>3 </span></li>
        <li>MongoDB <span>2 </span></li>
        <li>Bootstrap <span>{{ currentYear - 2019 }} </span></li>
        <li>Tailwind CSS <span>1</span></li>
        <li>Git <span>{{ currentYear - 2017 }} </span></li>
        <li>Tensorflow.js <span>0.5</span></li>
        <li>Socket.io <span>2</span></li>
        <li>AWS <span>1</span></li>
        <li>WeChat api <span>0.5</span></li>
        <li>Facebook api <span>2</span></li>
        <li>Google Analytics <span>2</span></li>
        <li>Baidu Analytics <span>1</span></li>
        
      </ul>
    </div>
  </div>
</div>

<div class="about-me-section theme-bg " [class.show-window]="isOpenAboutMe" [class.hide-window]="!isOpenAboutMe">
  <div class="close-btn second-color font-xl px-3" (click)="closeAboutMe()">&#10006;</div>
  <div class="row mx-0 h-100 justify-content-center align-items-center">
    
    <div class="portfo-wrap row justify-content-end justify-content-md-center">
      <div class="self-intro-wrap col-11 col-md-7 font-custom mx-0 justify-content-center second-color p-4 ms-auto ms-md-0">
        <ul>
          <li>Zoe Shu</li>
          <li>Living in Hong Kong🇭🇰</li>
          <li>Web Development Experience: {{ currentYear - 2017 }} years</li>
          <li>Love: 
            <ul>
              <li>Learn new things about programming</li>
              <li>Gaming: Nier:automata, Assassin's Creed, Diablo</li>
              <li>Reading: Literature, Philosophy</li>
              <li>Music: C-POP</li>
              <li>Drinks: Coffee, Alcohol</li>
            </ul>
          </li>
          <li>
            Hate: 
              <ul>
                <li>Boring work</li>
              </ul>
          </li>
        </ul>
      </div>
      <div class="col-auto portfo-img-wrap align-self-end">
        <picture >
          <source type="image/webp" srcset="../assets/img/web/memoji.webp">
          <source type="image/png" srcset="../assets/img/web/memoji.png">
          <img loading="lazy" src="../assets/img/web/memoji.png" alt="Portrait - Memoji">
        </picture>
      </div>
    </div>
  </div>
</div>