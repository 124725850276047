import { Component, OnInit } from '@angular/core';
declare let gtag: Function;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {

  isOpenSkillSet: boolean = false;
  isOpenAboutMe: boolean = false;
  today: Date = new Date();
  currentYear: number = this.today.getFullYear();

  constructor() { }

  ngOnInit(): void {
    this.closeSkillSet();
  }

  onClickLink(item) {
    gtag('event', item);
  }

  openSkillSet(){
    this.isOpenSkillSet = true;
  }
  
  closeSkillSet(){
    this.isOpenSkillSet = false;
  }

  openAboutMe(){
    this.isOpenAboutMe = true;
  }
  
  closeAboutMe(){
    this.isOpenAboutMe = false;
  }

}
