<div class="slide-content-wrap row mx-0 justify-content-center align-items-center px-5" 
  (swiperight)="onSwipeRight()" (swipeleft)="onSwipeLeft()"
>
  <div class="custom-caption col-12 col-md-5 col-xl-4 white-7 mt-5 text-start p-3">
    <div *ngIf="content.brand">
      <span class="bold second-color">Brand: </span> <span class=" c-text-shadow"> {{ content.brand }}</span>
    </div>
    <div *ngIf="content.location">
      <span class="bold second-color">Platform: </span> <span class=" c-text-shadow"> {{ content.location }}</span>
    </div>
    <p [innerHtml]="content.description" class="mt-4 c-text-shadow">
      
    </p>
    <div *ngIf="content.technologies" class="mt-4 align-items-start d-block mb-0">
      <span class="bold second-color">Technologies: </span>
      <ul class="mb-0 c-text-shadow">
        <li *ngFor="let tech of content.technologies">
          {{ tech }}
        </li>
      </ul>
    </div>
    <div *ngIf="content.challenges" class="mt-4 align-items-start d-block">
      <span class="bold second-color">Challenges: </span>
      <ul class=" c-text-shadow">
        <li *ngFor="let challenge of content.challenges">
          {{ challenge }}
        </li>
      </ul>
    </div>
    <div class="mt-4 align-items-start d-block">
      <span class="bold me-2 second-color">Share This Project: </span>
      <!-- <a target="_blank" href="https://zoeshu-playground.com/{{content.id}}">
        https://zoeshu-playground.com/{{content.id}}
      </a> -->
      <button *ngIf="true" class="custom-btn" 
        [cdkCopyToClipboard]="'https://zoeshu-playground.com/id/'+content.id" (click)="onClickCopy()"
      >
        <fa-icon [icon]="['far', 'copy']" class="pe-2 font-xl"></fa-icon>
        <span class="">{{clipboardTooltip}} URL</span>
      </button>
    </div>
    <a class="link-ins d-block text-center" 
        [href]="content.url" target="_blank" >
      <app-youtube-icon></app-youtube-icon>
    </a>
  </div>

  <div class="col-12 col-md-7 col-xl-auto mt-4 mx-3 mx-md-0">
    <button aria-label="Play" (click)="requestPlay()" class="custom-btn " [disabled]="isDisableReplay">
      <fa-icon [icon]="['fas', 'play']" class="pe-2 font-l"></fa-icon>Play
    </button>
    <div class="body-div d-block mx-auto" [class.background-center]="isBodyCenter">
      <div class="leaf-wrap">
        <img loading="lazy" class="leaf l-2 slow "
          [class]="{ 'move': isMove, 'animate': isAnimated }"  
          src="../../assets/img/vita/leaf2/leaf2.png" />
        <img loading="lazy" class="leaf l-1 mid "
          [class]="{ 'move': isMove, 'animate': isAnimated }"  
          src="../../assets/img/vita/leaf2/leaf1.png" />
        <img loading="lazy" class="leaf l-4 slow " 
          [class]="{ 'move': isMove, 'animate': isAnimated }" 
          src="../../assets/img/vita/leaf2/leaf4.png" />
        <img loading="lazy" class="leaf l-3 mid " 
          [class]="{ 'move': isMove, 'animate': isAnimated }" 
          src="../../assets/img/vita/leaf2/leaf3.png" />
        <img loading="lazy" class="leaf l-13 fast " 
          [class]="{ 'move': isMove, 'animate': isAnimated }" 
          src="../../assets/img/vita/leaf2/leaf8.png" />
        <img loading="lazy" class="leaf l-14 slow " 
          [class]="{ 'move': isMove, 'animate': isAnimated }" 
          src="../../assets/img/vita/leaf2/leaf4.png" />
        <img loading="lazy" class="leaf l-12 mid " 
          [class]="{ 'move': isMove, 'animate': isAnimated }" 
          src="../../assets/img/vita/leaf2/leaf10.png" />
        <img loading="lazy" class="leaf l-11 mid " 
          [class]="{ 'move': isMove, 'animate': isAnimated }" 
          src="../../assets/img/vita/leaf2/leaf2.png" />
        <img loading="lazy" class="leaf l-10 mid " 
          [class]="{ 'move': isMove, 'animate': isAnimated }" 
          src="../../assets/img/vita/leaf2/leaf10.png" />
        <img loading="lazy" class="leaf l-8 slow " 
          [class]="{ 'move': isMove, 'animate': isAnimated }" 
          src="../../assets/img/vita/leaf2/leaf8.png" />
        <img loading="lazy" class="leaf l-6 slow " 
          [class]="{ 'move': isMove, 'animate': isAnimated }" 
          src="../../assets/img/vita/leaf2/leaf6.png" />
        <img loading="lazy" class="leaf l-5 fast " 
          [class]="{ 'move': isMove, 'animate': isAnimated }" 
          src="../../assets/img/vita/leaf2/leaf5.png" />
        <img loading="lazy" class="leaf l-9 fast " 
          [class]="{ 'move': isMove, 'animate': isAnimated }" 
          src="../../assets/img/vita/leaf2/leaf9.png" />
        <img loading="lazy" class="leaf l-7 fast" 
          [class]="{ 'move': isMove, 'animate': isAnimated }" 
          src="../../assets/img/vita/leaf2/leaf7.png" />
      </div>
      <img loading="lazy" class="s-item head1" 
        [class.opacity-0]="firstOpacity" 
        src="../../assets/img/vita/lrec_super/super1.png" />
      <img loading="lazy" class="s-item head2" 
        [class.opacity-100]="secondOpacity"
        src="../../assets/img/vita/lrec_super/super2.png" />

      <div class="s-item c-btn-wrap">
        <img loading="lazy" class="c-btn" 
          src="../../assets/img/vita/lrec_super/button.png" />
        <div class="c-btn-flash" 
        [class]="{'opacity-100': secondOpacity, 'flash': isFlash }">
        </div>
      </div>

      <a class="c-link" [class.d-block]="isDisplayLink" 
        id="coupon-link" target="_blank"></a>
    </div>
  </div>

  <div class="col-12 col-sm-6 col-xl-7 mt-4 text-xl-end">
    <picture >
      <source type="image/webp" srcset="../../assets/img/vita_ad_cover.webp">
      <source type="image/jpeg" srcset="../../assets/img/vita_ad_cover.jpg">
      <img loading="lazy" src="../../assets/img/vita_ad_cover.jpg">
    </picture>
  </div>

  <div class="mt-4 text-center">
    <app-youtube-iframe [url]="content.url" class="mt-2"></app-youtube-iframe>
  </div>
  
</div>

