<div class="slide-content-wrap row mx-0 justify-content-center align-items-bottom px-5"
  (swiperight)="onSwipeRight()" (swipeleft)="onSwipeLeft()"
>
  <div class="custom-caption col-12 col-md-5 col-xl-3 white-7 mt-5 text-start p-3">
    <div *ngIf="content.title">
      <span class="bold second-color">Title: </span> <span class="c-text-shadow"> {{ content.title }}</span>
    </div>
    <div *ngIf="content.brand">
      <span class="bold second-color">Brand: </span> <span class="c-text-shadow"> {{ content.brand }}</span>
    </div>
    <div *ngIf="content.location">
      <span class="bold second-color">Venue: </span> <span class="c-text-shadow"> {{ content.location }}</span>
    </div>
    <p [innerHtml]="content.description" class="mt-4 c-text-shadow">
            
    </p>
    <div *ngIf="content.technologies" class="mt-4 align-items-start d-block">
      <span class="bold second-color">Technologies: </span>
      <ul class="mb-0 c-text-shadow">
        <li *ngFor="let tech of content.technologies">
          {{ tech }}
        </li>
      </ul>
    </div>
    <div *ngIf="content.challenges" class="mt-4 align-items-start d-block">
      <span class="bold second-color">Challenges: </span>
      <ul class=" c-text-shadow">
        <li *ngFor="let challenge of content.challenges">
          {{ challenge }}
        </li>
      </ul>
    </div>
    <div class="mt-4 align-items-start d-block">
      <span class="bold me-2 second-color">Share This Project: </span>
      <!-- <a target="_blank" href="https://zoeshu-playground.com/{{content.id}}">
        https://zoeshu-playground.com/{{content.id}}
      </a> -->
      <button *ngIf="true" class="custom-btn" 
        [cdkCopyToClipboard]="'https://zoeshu-playground.com/id/'+content.id" (click)="onClickCopy()"
      >
        <fa-icon [icon]="['far', 'copy']" class="pe-2 font-xl"></fa-icon>
        <span class="">{{clipboardTooltip}} URL</span>
      </button>
    </div>
  </div>

  <div class="col-12 col-md-6 col-xl-auto demo-col mt-4 mt-md-7 ms-0 ms-md-2 ms-xl-4">
    <app-custom-fields-form #formElem id="form-elem" class="col-md-9 col-12 form-elem" [parameters]="formParam" [userInput]="formInput" >
    </app-custom-fields-form>
  </div>

  <div class="col-12 col-sm-6 col-xl-7 mt-4 text-xl-end">
    <picture >
      <source type="image/webp" srcset="../../assets/img/web/form-config-1.webp">
      <source type="image/jpeg" srcset="../../assets/img/web/form-config-1.png">
      <img loading="lazy" src="../../assets/img/web/form-config-1.png">
    </picture>
  </div>
</div>