<div class="slide-wrap theme-bg" [class.show-window]="isShowSlide" [class.hide-window]="!isShowSlide">
  <div class="close-btn second-color font-xl px-3" (click)="onClickClose()">&#10006;</div>
  <ngb-carousel #itemSlide *ngIf="workContent" (slide)="onSlide($event)">
    <ng-template ngbSlide *ngFor="let content of workContent; let i = index" [id]="content.id">
      <ng-container *ngIf="!content.isExtra">
        <div class="slide-content-wrap row mx-0 justify-content-center align-items-start px-5" 
        (swiperight)="onSwipeRight(workContent, i)" (swipeleft)="onSwipeLeft(workContent, i)"
        >
          <div class="custom-caption col-12 col-md-5 col-xl-4 white-7 mt-5 text-start p-3">
            <div *ngIf="content.title">
              <span class="bold second-color">Title: </span> <span class=" c-text-shadow"> {{ content.title }}</span>
            </div>
            <div>
              <a *ngIf="!content.isYoutube && content.url" class="d-block text-start bold" 
                [href]="content.url" target="_blank" >
                {{ content.url }}
              </a>
            </div>
            <div *ngIf="content.brand">
              <span class="bold second-color">Brand: </span> <span class=" c-text-shadow"> {{ content.brand }}</span>
            </div>
            <div *ngIf="content.location">
              <span class="bold second-color">Venue: </span> <span class=" c-text-shadow"> {{ content.location }}</span>
            </div>
            <p [innerHtml]="content.description" class="mt-4 c-text-shadow">
              
            </p>
            <div *ngIf="content.technologies" class="mt-4 align-items-start d-block">
              <span class="bold second-color">Technologies: </span>
              <ul class="mb-0 c-text-shadow">
                <li *ngFor="let tech of content.technologies">
                  {{ tech }}
                </li>
              </ul>
            </div>
            <div *ngIf="content.challenges" class="mt-4 align-items-start d-block">
              <span class="bold second-color">Challenges: </span>
              <ul class=" c-text-shadow">
                <li *ngFor="let challenge of content.challenges">
                  {{ challenge }}
                </li>
              </ul>
            </div>
            <div class="mt-4 align-items-start d-block">
              <span class="bold me-2 second-color">Share This Project: </span>
              <!-- <a class="font-xs" target="_blank" href="https://zoeshu-playground.com/id/{{content.id}}">
                https://zoeshu-playground.com/id/{{content.id}}
              </a> -->
                <button *ngIf="true" class="custom-btn" 
                  [cdkCopyToClipboard]="'https://zoeshu-playground.com/id/'+content.id" (click)="onClickCopy()"
                >
                  <fa-icon [icon]="['far', 'copy']" class="pe-2 font-xl"></fa-icon>
                  <span class="">{{clipboardTooltip}} URL</span>
                </button>             
            </div>
            <a *ngIf="content.isYoutube" class="link-ins d-block text-center" 
                [href]="content.url" target="_blank" >
              <app-youtube-icon></app-youtube-icon>
            </a>
          </div>
          
          <ng-container *ngIf="content.isComingSoon">
            <div class="coming-soon-wrap col-12 col-md-6 col-xl-8 text-center mt-0 mt-md-5">
              No Image <span class="text-nowrap">;(</span>
            </div>
          </ng-container>

          <ng-container *ngIf="content.images && !content.isAnimated">
            <div class="col-12 col-md-7 col-xl-8 text-center text-xl-start mt-0 mt-md-5">
              <ng-container *ngFor="let img of content.images; let c = index;">
                <div class="d-inline-block mx-0 mx-md-2 mb-2 mt-4 mt-md-0"  *ngIf="c === 0">
                  <picture >
                    <source type="image/webp" [srcset]="img.path+'.webp'">
                    <source [type]="'image/'+img.format" [srcset]="img.path+'.'+img.format">
                    <img loading="lazy" [src]="img.path+'.'+img.format" [class.white-border]="img.border" [alt]="img.description">
                  </picture>
                </div>
              </ng-container>
            </div>
            <div class="col-12 text-center text-xl-start">
              <ng-container *ngFor="let img of content.images; let c = index;">
                <ng-container *ngIf="img.format != 'gif'">
                  <div class="mt-4 d-inline-block mx-0 mx-md-2 mb-2"  *ngIf="c != 0">
                    <picture >
                      <source type="image/webp" [srcset]="img.path+'.webp'">
                      <source [type]="'image/'+img.format" [srcset]="img.path+'.'+img.format">
                      <img loading="lazy" [src]="img.path+'.'+img.format" [class.white-border]="img.border" [alt]="img.description">
                    </picture>
                  </div>
                </ng-container>
                <ng-container *ngIf="img.format == 'gif'">
                  <div class="mt-4 d-inline-block mx-0 mx-md-2 mb-2 align-top" >
                    <img loading="lazy" [src]="img.path+'.'+img.format" [class.white-border]="img.border" [alt]="img.description" >
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="content.images && content.isAnimated">
            <div class="col-12 col-md-6 col-xl-8 text-center text-xl-start mt-5 mb-4">
              <picture >
                <source type="image/webp" [srcset]="content.coverImage+'.webp'">
                <source type="image/jpeg" [srcset]="content.coverImage+'.jpg'">
                <img loading="lazy" [src]="content.coverImage+'.jpg'" [alt]="content.title">
              </picture>
            </div>
            <div class="mt-4 mb-4 col-12 text-center text-xl-start">
              <div class="d-flex justify-content-center">
                <button type="button" aria-label="Pause" class="custom-btn me-2" (click)="onPause(content.id)" *ngIf="isPaused != content.id">
                  <fa-icon [icon]="['fas', 'pause']" class="font-l pe-2" ></fa-icon>Pause
                </button> 
                <button type="button" aria-label="Contiue" class="custom-btn me-2" (click)="onContiue(content.id)" *ngIf="isPaused == content.id">
                  <fa-icon [icon]="['fas', 'play']" class="font-l pe-2"></fa-icon>Contiue
                </button> 
                <button type="button" aria-label="Replay" class="custom-btn" (click)="onReplay()">
                  <fa-icon [icon]="['fas', 'undo']" class="font-l pe-2"></fa-icon>Replay
                </button>
              </div>
              <div class="animated-img-wrap position-relative text-center">
                <img loading="lazy" class="opacity-0" [src]="content.images[0].path+'.jpg'" alt="">
                <div class="animate-container position-absolute mb-4" [id]="content.id+'_animation'" 
                  [class.paused]="isPaused == content.id" [class.animated]="isAnimated"
                >
                <div *ngFor="let img of content.images;">
                  <picture>
                    <source type="image/webp" [srcset]="img.path+'.webp'">
                    <source type="image/jpeg" [srcset]="img.path+'.jpg'">
                    <img loading="lazy" [src]="img.path+'.jpg'" [alt]="img.description">
                  </picture>
                </div>
                
                </div>
              </div>
            </div>
          </ng-container>

          <div class="mt-4 text-center" *ngIf="content.isYoutube" >
            <app-youtube-iframe [url]="content.url" class="mt-2"></app-youtube-iframe>
          </div>

        </div>
      </ng-container>

      <ng-container *ngIf="content.id=='lancome-firework'">
        <app-demo-lancome-fireworks #lancomeFireworksDemoElem 
          (swipeLeft)="onSwipeLeft(workContent, i)" (swipeRight)="onSwipeRight(workContent, i)">
        </app-demo-lancome-fireworks>
      </ng-container>

      <ng-container *ngIf="content.id=='vita-vlt-ad'">
        <app-demo-vita #vitaDemoElem 
          (swipeLeft)="onSwipeLeft(workContent, i)" (swipeRight)="onSwipeRight(workContent, i)">
        </app-demo-vita>
      </ng-container>

      <ng-container *ngIf="content.id=='demo-form'">
        <app-demo-generate-form #formDemoElem 
          (swipeLeft)="onSwipeLeft(workContent, i)" (swipeRight)="onSwipeRight(workContent, i)">
        </app-demo-generate-form>
      </ng-container>
    </ng-template>

    <!-- <ng-template ngbSlide id="lancome-firework" *ngIf="content.id=='lancome-firework'">
      <app-demo-lancome-fireworks #lancomeFireworksDemoElem (swipeLeft)="onSwipeLeft()" (swipeRight)="onSwipeRight()"></app-demo-lancome-fireworks>
    </ng-template> -->

    <!-- <ng-template ngbSlide id="vita-vlt-ad">
      <app-demo-vita #vitaDemoElem (swipeLeft)="onSwipeLeft()" (swipeRight)="onSwipeRight()"></app-demo-vita>
    </ng-template>

    <ng-template ngbSlide id="demo-form">
      <app-demo-generate-form #formDemoElem (swipeLeft)="onSwipeLeft()" (swipeRight)="onSwipeRight()"></app-demo-generate-form>
    </ng-template> -->
  </ngb-carousel>
</div>