import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-demo-vita',
  templateUrl: './demo-vita.component.html',
  styleUrls: ['./demo-vita.component.scss']
})
export class DemoVitaComponent implements OnInit {
  @Output() swipeLeft = new EventEmitter<string>();
  @Output() swipeRight = new EventEmitter<string>();
  
  isMove: boolean = true;
  isDisplayLink: boolean = false;
  firstOpacity: boolean = false;
  secondOpacity: boolean = false;
  isFlash: boolean = true;
  isAnimated: boolean = false;
  isBodyCenter: boolean = false;
  isDisableReplay: boolean = false;
  clipboardTooltip: string = 'Copy';

  content: any;

  constructor() { }

  ngOnInit(): void {
    this.clipboardTooltip = 'Copy';
    this.initContent();
  }

  private initContent(){
    this.content = {
      id: 'vita-vlt-ad',
      order: '0018',
      title: 'Vita VLT Lemon Tea',
      brand: 'Vita VLT',
      location: 'Apple Daily Web & Mobile App',
      technologies: [ 
        'Node.js',
        'Shake.js for detecting is user shaked the device',
        'CSS Animation',
      ],
      challenges: [
        'CSS Animation for leaf motions',
        'Required to shake the device to trigger next step',
        'Minimized the file size for in-app advertising'
      ],
      description: `In-app ad and webpage ad for Hong Kong famous lemon tea band, Vita VLT.`,
      url: 'https://www.youtube.com/embed/ljhP9tZMa1s',
      isYoutube: false,
      isAnimated: false,
      isExtra: true,
      preview: {
        path: '../../assets/img/vita_ad_cover',
        format: 'jpg'
      },
    }
  }

  public resetAnimation(){
    this.isMove = true;
    this.isAnimated = false;
    this.firstOpacity = false;
    this.secondOpacity = false;
    this.isFlash = false;
    this.isDisplayLink = false;
    this.isBodyCenter = false;
  }

  public playAnimation(){
    if(!this.isDisableReplay){
      this.isDisableReplay = true;
      setTimeout(() => {
        this.initAnimation();
      }, 1000);
    }
  }

  private initAnimation(){
    this.isMove = false;
    this.isAnimated = true;

    setTimeout(() => {
      this.firstOpacity = true;
    }, 1000);

    setTimeout(() => {
      this.secondOpacity = true;
      this.isFlash = true;
      this.isDisplayLink = true;
      this.isBodyCenter = true;
    }, 2500);

    setTimeout(() => {
      this.isDisableReplay = false;
    }, 3000);
  }

  public requestPlay(){
    this.resetAnimation();
    this.playAnimation();
  }

  onSwipeLeft() {
    this.resetClickCopy();
  }

  onSwipeRight() {
    this.resetClickCopy();
  }

  onClickCopy(){
    this.clipboardTooltip = 'Copied';
    setTimeout(() => {
      this.resetClickCopy();
    }, 10000)
  }

  resetClickCopy(){
    this.clipboardTooltip = 'Copy';
  }
}
