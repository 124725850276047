import { Component, Injector, Input, OnChanges, OnInit } from '@angular/core';
// import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ngbDateToString, stringToNgbDate } from 'src/app/helpers/formatter';
import badWordsJson from './bad-words.json';

// import { CustomDateParserFormatter } from 'src/lib/presenter/custom-date-parser-formatter';

import { InputFieldType } from './input-field-type.enum';

@Component({
  selector: 'app-custom-fields-form',
  templateUrl: './custom-fields-form.component.html',
  styleUrls: ['./custom-fields-form.component.scss'],
  // providers: [
  //   // { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  // ]
})
export class CustomFieldsFormComponent implements OnInit, OnChanges {
  @Input() parameters = {
    showFieldsError: false,
    isEditMode: false,
    displayFieldAsRow: false,
    displayFieldAsCol: true,
    displayAllAsRow: false,
    instruction: '',
    header: {
      id: '',
      title: '',
      customClass: '',
      customInputClass: '',
      mandatory: false,
    },
    headerBtn: {},
    fieldList: [],
    fieldOptions: {
      'field' : [
        {
          id: 'id',
          key: 'key',
          value: 'value',
          label: 'label',
        }
      ]
    },
    fieldRowClass: '',
    onChangeBtn:[],
  };
  @Input() userInput;

  InputFieldType = InputFieldType;
  isShowOnChangeBtn: boolean = true;
  copyFormValue: any = {};
  copyHeaderValue: string;
  dateInputList: any = {};

  // Row / Col style for form
  styleForm: string;
  styleCustomFieldRow: string;
  styleFieldTitle: string;
  styleFieldInputWrap: string;
  styleOnChangeBtn: string;

  constructor(){}
  // constructor(injector: Injector, private parserFormatter: NgbDateParserFormatter, private dateAdapter: NgbDateAdapter<string>) {
  // }

  ngOnInit() {
    this.checkParameters();
    this.initStyleClass();
    this.initUserInput();
  }

  ngOnChanges() {
    this.formatInputDate();
  }

  checkParameters(){
    if(!this.parameters.displayFieldAsCol 
      && !this.parameters.displayFieldAsRow 
      && !this.parameters.displayAllAsRow
    ){
      this.parameters.displayFieldAsRow = true;
    }
    if(!this.parameters.fieldRowClass){
      this.parameters.fieldRowClass = '';
    }
  }

  initStyleClass(){
    if(this.parameters.displayFieldAsCol){
      this.styleForm = 'row mx-0 col-10 px-0';
      this.styleCustomFieldRow = 'col-auto';
      this.styleFieldTitle = '';
      this.styleFieldInputWrap = '';
      this.styleOnChangeBtn = 'col-2';
    }else if(this.parameters.displayFieldAsRow){
      this.styleForm = 'col-12';
      this.styleCustomFieldRow = 'row mx-0 mt-3 text-start';
      this.styleFieldTitle = 'col-12 col-sm-4 mb-sm-0 mb-1 px-0';
      this.styleFieldInputWrap = 'col-12 col-sm-8 px-0';
      this.styleOnChangeBtn = 'col-12';
    }else if(this.parameters.displayAllAsRow){
      this.styleForm = 'col-12 px-0';
      this.styleCustomFieldRow = 'row mx-0 mt-3';
      this.styleFieldTitle = 'col-12';
      this.styleFieldInputWrap = 'col-12';
      this.styleOnChangeBtn = 'col-lg-auto col-12';
    }
  }

  private initUserInput(){
    this.parameters.fieldList.forEach(field => {
      if(field.type == InputFieldType.checkbox){
        this.userInput[field.id] = {};
        this.parameters.fieldOptions[field.id].forEach(option => {
          this.userInput[field.id][option.key] = option['value'];
        });
      }
    });
  }

  public copyInputData(userInput) {
    this.copyHeaderValue = userInput[this.parameters.header['id']];
    this.parameters.fieldList.forEach(field => {
      if(field.type == InputFieldType.checkbox){
        this.copyFormValue[field.id] = Object.assign({}, userInput[field.id]);
      }else{
        this.copyFormValue[field.id] = userInput[field.id];
      }
    });
  }

  public discardForm() {
    /**
     * discardForm() need to work with copyInputData(), 
     * use copyInputData() on first init & after data updated/created
     **/
    this.userInput[this.parameters.header['id']] = this.copyHeaderValue;
    this.parameters.fieldList.forEach(field => {
      if(field.type == InputFieldType.checkbox){
        this.userInput[field.id] = Object.assign({}, this.copyFormValue[field.id]);
      }else{
        this.userInput[field.id] = this.copyFormValue[field.id];
      }
    });
  }

  public clearField(fieldId){
    let field = this.parameters.fieldList.find(field => field.id == fieldId);
    switch (field.type){
      case InputFieldType.date: 
        this.dateInputList[fieldId] = undefined;
        this.onChangeDate(fieldId);
        break;
      default :
        this.userInput[fieldId] = undefined;
        break;
    }
  }

  onChangeFields(){
    this.isShowOnChangeBtn = true;
    this.parameters['showFieldsError'] = false;
  }

  onChangeCheckBox(fieldIndex, value, optionIndex){
    if(this.userInput[fieldIndex].length <= 0){
      this.userInput[fieldIndex] = [];
      this.userInput[fieldIndex][optionIndex] = value;
    }else{
      this.userInput[fieldIndex][optionIndex] = value;
    }
  }

  onChangeDate(fieldId) {
    if(typeof(this.dateInputList[fieldId]) === 'object'){
      let dateString = ngbDateToString(this.dateInputList[fieldId]);
      if(dateString){
        this.userInput[fieldId] = moment(dateString).format('DD-MM-YYYY');
      }else{
        this.userInput[fieldId] = undefined;
      }
    }else{
      this.userInput[fieldId] = this.dateInputList[fieldId]
    }
  }

  public formatDate(fieldId){
    if(typeof(this.dateInputList[fieldId]) === 'string'){
      this.dateInputList[fieldId] = stringToNgbDate(moment(this.userInput[fieldId]).format('DD-MM-YYYY'));
      let dateString = ngbDateToString(this.dateInputList[fieldId]);
      this.userInput[fieldId] = moment(dateString).format('DD-MM-YYYY');
    }
  }

  public formatInputDate(){
    this.parameters.fieldList.forEach(field => {
      if(field.type == InputFieldType.date){
        if(this.userInput[field.id]){
          this.dateInputList[field.id] = stringToNgbDate(moment(this.userInput[field.id]).format('DD-MM-YYYY'));
        }else{
          this.dateInputList[field.id] = null;
        }
      }
    });
  }

  public setMinDateByField(targetFieldId, minDateId){
    this.parameters.fieldList.find(field => field.id == targetFieldId)['minDate'] = this.dateInputList[minDateId];
  }

  public setMaxDateByField(targetFieldId, maxDateId){
    this.parameters.fieldList.find(field => field.id == targetFieldId)['maxDate'] = this.dateInputList[maxDateId];
  }

  public checkBadWords(msg){
    msg = ' '+msg+' ';
    let regexp = new RegExp(badWordsJson.badWordsList.join(" | "), "g");
    if (msg.match(regexp) !== null) {
      return true;
    }else{
      return false;
    }
  }
}
