import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { Inject, Injectable, InjectionToken, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HomeComponent } from './pages/home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from './components/profile/profile.component';
import { WorksComponent } from './components/works/works.component';
import { ContactComponent } from './components/contact/contact.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from './../environments/environment';
import { DemoVitaComponent } from './components/demo-vita/demo-vita.component';
import { YoutubeIconComponent } from './components/youtube-icon/youtube-icon.component';
import { DemoLancomeFireworksComponent } from './components/demo-lancome-fireworks/demo-lancome-fireworks.component';
import { BeefPatternComponent } from './components/beef-pattern/beef-pattern.component';
import { SafeHtmlPipe } from './helpers/safe-html.pipe';
import { SafeUrlPipe } from './helpers/safe-url.pipe';
import { YoutubeIframeComponent } from './components/youtube-iframe/youtube-iframe.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { CustomFieldsFormComponent } from './components/custom-fields-form/custom-fields-form.component';
import { DemoGenerateFormComponent } from './components/demo-generate-form/demo-generate-form.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatButtonModule } from '@angular/material/button';
import { PLATFORM_ID, isDevMode } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { windowProviders } from './services/window.providers';
import { CachingInterceptor } from './services/caching.interceptor';

export const EnvironmentToken = new InjectionToken('ENVIRONMENT');

@Injectable({providedIn: 'root'}) export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    'pinch': { enable: false },
    'rotate': { enable: false },
    // 'swipe': { direction: Hammer.DIRECTION_HORIZONTAL },
  };
}

declare let gtag: Function;
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    WorksComponent,
    ContactComponent,
    DemoVitaComponent,
    YoutubeIconComponent,
    DemoLancomeFireworksComponent,
    BeefPatternComponent,
    SafeHtmlPipe,
    SafeUrlPipe,
    YoutubeIframeComponent,
    CustomFieldsFormComponent,
    DemoGenerateFormComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule,
    BrowserAnimationsModule,
    DragDropModule,
    HammerModule,
    NgSelectModule,
    FormsModule,
    ClipboardModule,
    MatButtonModule,
  ],
  providers: [
    { provide: EnvironmentToken, useValue: environment },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    windowProviders
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule { 
  constructor(
    @Inject(EnvironmentToken) private env: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    library: FaIconLibrary
  ) {
    library.addIconPacks(fas, far, fab);
      if (isPlatformBrowser(this.platformId)) {
          gtag('config', this.env.google.GA_TRACKING_ID);
      }
  }
}
