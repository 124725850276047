import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-demo-lancome-fireworks',
  templateUrl: './demo-lancome-fireworks.component.html',
  styleUrls: ['./demo-lancome-fireworks.component.scss']
})
export class DemoLancomeFireworksComponent implements OnInit {
  @Output() swipeLeft = new EventEmitter<string>();
  @Output() swipeRight = new EventEmitter<string>();
  
  content: any;
  clipboardTooltip: string = 'Copy';

  constructor() { }

  ngOnInit(): void {
    this.clipboardTooltip = 'Copy';
    this.initContent();
  }

  initContent(){
    this.content = {
      id: 'lancome-firework',
      order: '0024',
      title: 'Lancôme CNY 2019 Wish Big',
      isExtra: true,
      brand: 'Lancôme APAC',
      location: 'Bangkok',
      technologies: [ 'Node.js', 'Socket.io' ],
      challenges: [
        'CSS Animation',
        'Exchange data with LED control system',
        'Queue up to play animation if multi player'
      ],
      description: `A specially curated Lancôme pop-up encouraging visitors 
        to explore retail and digital offerings has opened at the King Power Rangnam downtown complex.
        <br><br>
        A multi-sensory journey begins from the outside, 
        with a sea of red light illuminating the building. 
        An augmented reality (AR) photobooth allows visitors to 
        take photos of themselves holding a \‘virtual\’ puppy dog and 
        they can interact with a touch-screen digital game. Limited edition CNY offerings 
        and travel exclusives are available at the pop-up, which also offers an engraving service on any purchase of L’Absolu Rouge.`,
      url: 'https://www.youtube.com/embed/Ev1n3d0U71c',
      isYoutube: false,
      isAnimated: false,
      preview: {
        path: '../../assets/img/lancome_firework',
        format: 'jpg'
      },
      
    }
  }

  onSwipeLeft() {
    this.resetClickCopy();
  }

  onSwipeRight() {
    this.resetClickCopy();
  }

  onClickCopy(){
    this.clipboardTooltip = 'Copied';
    setTimeout(() => {
      this.resetClickCopy();
    }, 10000)
  }

  resetClickCopy(){
    this.clipboardTooltip = 'Copy';
  }
}
