<div id="custom-fields-form-wrap" class="custom-fields-form-wrap" [class.view-mode]="!parameters.isEditMode">

  <div id="custom-fields-header-wrap" *ngIf="parameters?.header"
    class="custom-fields-header-wrap border-bottom row mx-0 mb-4 justify-content-between align-items-center"
  >
    <ng-container *ngIf="parameters.header.title">
      <div class="header-title col-auto" [class]="parameters.header?.customClass">
        {{ parameters.header.title }}
      </div>
    </ng-container>
  </div>

  <div id="custom-fields-body-wrap" *ngIf="parameters.fieldList" class="custom-fields-body-wrap pb-4 row mx-0">
    <form class="custom-field-body" *ngIf="parameters.fieldList" [class]="styleForm">
      <div *ngIf="parameters.instruction" [class]="styleCustomFieldRow" >
        {{ parameters.instruction }}
      </div>
      <ng-container *ngFor="let field of parameters.fieldList; let f = index">
        <div class="custom-field-row justify-content-center align-items-center" 
          [class]="styleCustomFieldRow +' '+ parameters?.fieldRowClass"
          *ngIf="!field.disabled"
        > 
          
          <div class="field-title" [class]="field?.titleCustomClass +' '+ styleFieldTitle">
            <span class="field-title-text">{{ field.name }}
              <span class="yt-red" *ngIf="field.mandatory">*</span>
            </span>
            <span class="field-tip ms-2" *ngIf="field.showTip" [ngbTooltip]="fieldToolTip" placement="bottom">
              <fa-icon [icon]="['fas', 'question-circle']" ></fa-icon>
              <ng-template #fieldToolTip>
                <div class="warning-tooltip-wrap tooltip-inner text-center large-container">
                  {{ field.tooltipMsg }}
                </div>
              </ng-template>
            </span> 
          </div>

          <!-- Edit Mode -->
          <div class="field-input-wrap" 
            *ngIf="parameters.isEditMode" [class]="styleFieldInputWrap">
            <ng-container [ngSwitch]="field.type">
              <ng-container *ngSwitchDefault>
                <input [type]="field.type" class="form-control" [id]="field.id+'-input'" [class]="field?.inputCustomClass" [name]="field.id"
                  [(ngModel)]="userInput[field.id]" [disabled]="field?.disabled" [placeholder]="field?.placeholder" 
                  [class.input-error]="(field.mandatory && !userInput[field.id] && parameters.showFieldsError) || (field.isError && parameters.showFieldsError)"
                  (change)="field.onChange(); onChangeFields();" 
                />
                <div class="yt-red font-s pt-1" *ngIf="field.errorMsg">
                  {{ field.errorMsg }}
                </div>
              </ng-container>
              
              <ng-container *ngSwitchCase="InputFieldType.textarea">
                <textarea class="form-control" [rows]="field.rowLength" [maxlength]="field.maxLength"
                  [id]="field.id+'-input'" [class]="field?.inputCustomClass" [name]="field.id"
                  [(ngModel)]="userInput[field.id]" [disabled]="field?.disabled" [placeholder]="field?.placeholder" 
                  [class.input-error]="(field.mandatory && !userInput[field.id] && parameters.showFieldsError) || (field.isError && parameters.showFieldsError)"
                  (change)="field.onChange(); onChangeFields();"
                >
                </textarea>
                <div class="yt-red font-s pt-1" *ngIf="field.errorMsg">
                  {{ field.errorMsg }}
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="InputFieldType.number">
                <input [type]="field.type" class="form-control" [id]="field.id+'-input'" [class]="field?.inputCustomClass" [name]="field.id"
                  [(ngModel)]="userInput[field.id]" [disabled]="field?.disabled" [placeholder]="field?.placeholder" [min]="field?.min" [max]="field?.max" [step]="field?.step"
                  [class.input-error]="(field.mandatory && !userInput[field.id] && parameters.showFieldsError) || (field.isError && parameters.showFieldsError)"
                  (change)="field.onChange(); onChangeFields();" 
                />
                <div class="yt-red font-s pt-1" *ngIf="field.errorMsg">
                  {{ field.errorMsg }}
                </div>
              </ng-container>
              
              <ng-container *ngSwitchCase="InputFieldType.checkbox">
                <ng-container *ngIf="parameters.fieldOptions">
                  <div [class.input-error]="(field.mandatory && !userInput[field.id] && parameters.showFieldsError) || (field.isError && parameters.showFieldsError)">
                    <div class="custom-checkbox position-relative custom-control d-inline-block me-2" *ngFor="let option of parameters.fieldOptions[field.id]; let o = index">
                      <input type="checkbox" [id]="option.id+'-checkbox'" class="checkbox custom-control-input p-0 align-middle me-1" [name]="option.id" [value]="option.key"
                        [(ngModel)]="userInput[field.id][option.key]" [disabled]="option?.disabled" (change)="field.onChange(); onChangeFields();" 
                        [class.input-error]="(field.mandatory && !userInput[field.id][option.key] && parameters.showFieldsError) || (field.isError && parameters.showFieldsError)"
                      />
                      <label [for]="option.id+'-checkbox'" class="custom-control-label mb-0">
                        {{ option.label }}
                      </label>
                      <div class="yt-red font-m" *ngIf="field.errorMsg">
                        {{ field.errorMsg }}
                      </div>
                    </div>
                  </div>
                  <div class="yt-red font-s pt-1" *ngIf="field.errorMsg">
                    {{ field.errorMsg }}
                  </div>
                </ng-container>
                
              </ng-container>

              <ng-container  *ngSwitchCase="InputFieldType.radio">
                <div [class.input-error]="(field.mandatory && !userInput[field.id] && parameters.showFieldsError) || (field.isError && parameters.showFieldsError)">
                  <div class="custom-control custom-radio d-inline-block me-2" [class]="field?.customClass" *ngFor="let option of parameters.fieldOptions[field.id]; let o = index">
                    <input type="radio" class="custom-control-input me-1" [id]="option.id+'-radio'" [name]="field.id" [value]="option.value" 
                      [(ngModel)]="userInput[field.id]" [disabled]="option?.disabled" 
                      (change)="field.onChange(); onChangeFields();" 
                    />
                    <!-- [class.input-error]="(field.mandatory && !userInput[field.id] && parameters.showFieldsError) || (field.isError && parameters.showFieldsError)" -->
                    <label class="custom-control-label" [for]="option.id+'-radio'">
                      {{ option.label }}
                    </label> 
                  </div>
                </div>
                <div class="yt-red font-s pt-1" *ngIf="field.errorMsg">
                  {{ field.errorMsg }}
                </div>
              </ng-container>

              <ng-container  *ngSwitchCase="InputFieldType.dropdown">
                <ng-select [id]="field.id+'-dropdown'" class="" [multiple]="field.multiple" [closeOnSelect]="true" [name]="field.id"
                  [items]="parameters.fieldOptions[field.id]" bindLabel="label" bindValue="value" [(ngModel)]="userInput[field.id]"
                  [disabled]="field?.disabled" [clearable]="field?.clearable" [placeholder]="field?.placeholder" 
                  [closeOnSelect]="field.closeOnSelect" [addTag]="field?.addTag" [loading]="field?.isLoading"
                  (change)="field.onChange(); onChangeFields();" (scrollToEnd)="field.scrollToEnd()" (search)="field.onSearch($event)"
                  (clear)="clearField(field.id); field.onClear($event); "
                  [class.input-error]="(field.mandatory && !userInput[field.id] && parameters.showFieldsError) || (field.isError && parameters.showFieldsError)"
                >
                  <ng-template ng-option-tmp let-item="item" *ngIf="field.template">
                    <div class="ng-select-wrap-option" [title]="item?.label">
                      {{ item.label }}
                    </div>
                  </ng-template>
                </ng-select>
                <div class="yt-red font-s pt-1" *ngIf="field.errorMsg">
                  {{ field.errorMsg }}
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="InputFieldType.date">
                <div class="position-relative calendar-input-wrap">
                  <input type="text" [id]="field.id+'-datepicker'" class="form-control date-picker-input-box "
                    [class.input-error]="(field.mandatory && !dateInputList[field.id] && parameters.showFieldsError) || (field.isError && parameters.showFieldsError)"
                    [placeholder]="field?.placeholder" [name]="field.id" [maxDate]="field?.maxDate" [minDate]="field?.minDate"
                    [(ngModel)]="dateInputList[field.id]" (ngModelChange)="onChangeDate(field.id); field.onChange(); onChangeFields()" 
                    ngbDatepicker #datePicker="ngbDatepicker" autocomplete="off" (click)="datePicker.toggle()" (blur)="formatDate(field.id)"
                  >
                  <div class=" calendar" type="button" (click)="datePicker.toggle()" >
                    <fa-icon [icon]="['far','calendar']" class="font-l second-color" aria-hidden="true"></fa-icon>
                  </div>
                </div>
                <div class="yt-red font-s pt-1" *ngIf="field.errorMsg">
                  {{ field.errorMsg }}
                </div>
              </ng-container>

            </ng-container>
          </div>

          <!-- View Mode -->
          <ng-container [ngSwitch]="field.type">
            <div class="field-input-wrap view-input auto-add-seperate" [class.d-none]="parameters.isEditMode"
              [class]="styleFieldInputWrap"
            >
              <ng-container  *ngSwitchDefault >
                {{ userInput[field.id] }}
              </ng-container>

              <ng-container *ngSwitchCase="InputFieldType.checkbox">
                <ng-container *ngIf="field.showPlainText">
                  <ng-container *ngIf="parameters.fieldOptions[field.id] && userInput[field.id]">
                    <ng-container *ngFor="let option of parameters.fieldOptions[field.id]; let o = index"> 
                      <ng-container *ngIf="userInput[field.id][option.key]">
                        <span class="view-checkbox seperate-item">{{ option.label }}</span>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="!field.showPlainText">
                  <ng-container *ngIf="parameters.fieldOptions[field.id] && userInput[field.id]">
                    <div class="custom-checkbox position-relative custom-control d-inline-block me-2 view-disabled-input" *ngFor="let option of parameters.fieldOptions[field.id]; let o = index">
                      <input type="checkbox" disabled class="checkbox custom-control-input p-0 " [name]="'view-'+option.id" [value]="option.key"
                        [(ngModel)]="userInput[field.id][option.key]"  
                      />
                      <label class="custom-control-label mb-0">
                        {{ option.label }}
                      </label>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="InputFieldType.radio">
                <ng-container *ngIf="field.showPlainText">
                  <ng-container *ngFor="let option of parameters.fieldOptions[field.id]">
                    <ng-container *ngIf="option.value == userInput[field.id]" >
                      {{ option.label }}
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="!field.showPlainText">
                  <div class="custom-control custom-radio d-inline-block me-2 view-disabled-input" [class]="field?.customClass" *ngFor="let option of parameters.fieldOptions[field.id]; let o = index">
                    <input type="radio" disabled class="custom-control-input me-1" [name]="'view-'+field.id" [value]="option.value" 
                      [(ngModel)]="userInput[field.id]"
                    />
                    <label class="custom-control-label">
                      {{ option.label }}
                    </label>
                  </div>
                </ng-container>
              </ng-container>
              
              <ng-container  *ngSwitchCase="InputFieldType.dropdown">
                <ng-container *ngFor="let option of parameters.fieldOptions[field.id]">
                  <ng-container *ngIf="!field.multiple && option.value == userInput[field.id]">
                      {{ option.label }}
                  </ng-container>
                </ng-container>
              
                <ng-container *ngIf="field.multiple">
                  <ng-container *ngFor="let input of userInput[field.id]; let i = index">
                    <ng-container *ngFor="let option of parameters.fieldOptions[field.id]">
                      <ng-container *ngIf="option.value == input[i]" >
                        <span class="view-checkbox">{{ option.label }}</span>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="InputFieldType.date">
                {{userInput[field.id]}}
              </ng-container>
              

            </div>
          </ng-container>
        </div>
      </ng-container>
    </form>

    <div class="col-12 align-self-center text-center mt-4" *ngIf="isShowOnChangeBtn && parameters.isEditMode"
      [class]="styleOnChangeBtn"
    >
      <button class="custom-btn on-change-btn-text" [id]="button.id" *ngFor="let button of parameters.onChangeBtn" 
        [class]="button?.customClass" [disabled]="button?.disabled" (click)="$event.stopPropagation(); button.onClick()"
      >
        <fa-icon [icon]="button?.icon" class="on-change-btn-icon" [class]="button?.iconClass" ></fa-icon>
        <!-- <i [class]="button?.icon" class="on-change-btn-icon"></i> -->
        <span class="on-change-btn-text">{{ button.text }}</span>
      </button>
    </div>

  </div>

  <div class="header-btn-wrap col-auto" *ngIf="parameters.headerBtn" [class.d-none]="parameters.header['hideBtn']">
    <button *ngFor="let button of parameters.headerBtn; let b = index" class="custom-btn"
      [class]="button.customClass" [type]="button?.type" [id]=" 'header-btn-' + button.id" 
      (click)="$event.stopPropagation();button.onClick()" 
    >
      <fa-icon *ngIf="button.icon"  [icon]="button?.icon" [class]="button?.iconClass" [id]=" 'header-btn-' + button.id+ '-icon' "></fa-icon>
      <!-- <i *ngIf="button.icon" [class]="button.icon" [id]=" 'header-btn-' + button.id+ '-icon' "></i> -->
      <span [id]=" 'header-btn-' + button.id + '-text'" class="header-btn-text">{{ button.text }}</span>
    </button>
  </div>
</div> 