import { Component, ElementRef, HostListener, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
// import { ProjectService } from 'src/app/services/project.service';
import { ProjectInfo } from 'src/app/entity/class/project-info';

declare let gtag: Function;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  
})
export class ProfileComponent implements OnInit {
  @ViewChild('worksComponent', {static: true}) worksComponent;
  @ViewChild('workItemWrap') workItemWrap : ElementRef;
  @Input() cssTheme: string;

  protected route: ActivatedRoute;
  
  idList: any = [];
  // isBBTheme: boolean;
  workContent: any;
  usedTheme: any;
  // themeIcon: string = 'theme-blueberry';
  itemShadow: string;
  shadowLevel: any = [];
  bodyHeight: number;
  bgPosY: string;
  shadow: number = 4;

  // projectListDB: AngularFireList<any>;
  projectList: ProjectInfo[];

  today: Date = new Date();
  currentYear: number = this.today.getFullYear();

  constructor(
    injector: Injector, 
    // private _projectService: ProjectService,  
    // private angularFireDatabase: AngularFireDatabase,
    private _location: Location
  ) {  
    this.route = injector.get(ActivatedRoute);
    // this.getAllProjectData();  
  }  
  
  @HostListener('window:resize', ['$event']) onResize(event){
    // this.setShadowLevel();
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll(event) {
    requestAnimationFrame(() => {
      this.onScroll(event);
    });
  }
  
  onScroll(event) {
      const scroll = Math.floor(event.srcElement.scrollingElement.scrollTop);
      let posY = Math.min(100, Math.max(0, Math.floor(scroll / 10)));
      this.bgPosY = `${posY}%`;
  }
  // @HostListener('window:scroll', ['$event']) onScroll(event) {
  //   let scroll = Math.floor(event.srcElement.scrollingElement.scrollTop);
  //   let posY = Math.floor(scroll/10);
  //   posY =  posY > 100 ? 100 : posY < 0 ? 0 : posY;
  //   this.bgPosY = posY+'%';
  //   // for(let i=0; i <this.shadowLevel.length; i++) {
  //   //   if (scroll < this.shadowLevel[i]){
  //   //     let pixel = 4-i;
  //   //     this.itemShadow = pixel+'px '+pixel+'px white';
  //   //     break;
  //   //   }
  //   // }
  // }

  ngOnInit() {
    this.initWorkItem();
    this.setIdList();
    this.checkRoute();
    // this.isBBTheme = this.cssTheme == 'theme-blueberry' ? true : false;
    this.worksComponent.hideSlide();
  }

  ngAfterViewInit() {
    // this.setShadowLevel();
  }

  checkRoute(){
    let project = this.route.snapshot.paramMap.get('project');
    if(project && this.idList.includes(project)){
      setTimeout(() => {
        this.onClickDot(project);
      }, 2000);
    }else{
      this._location.go("");
    }
  }

  onClickProject(project) {
    gtag('event', project);
  }

  // setShadowLevel(){
  //   this.bodyHeight = document.body.scrollHeight;
  //   this.bodyHeight = this.workItemWrap.nativeElement.offsetHeight;
  //   let level = 9;
  //   let base = Math.floor(this.bodyHeight/level);
  //   this.shadowLevel = [];
  //   for(let i = 1; i <= level; i++){
  //     this.shadowLevel.push(base*i);
  //   }
  // }

  counter(i: number) {
    return new Array(i);
  }

  onClickDot(itemId){
    this.worksComponent.selectItem(itemId);
    this.worksComponent.showSlide();
    // window.history.pushState("", itemId, "/id/" + itemId);
    this._location.go( "/id/" + itemId);

    if(itemId == 'vita-vlt-ad'){
      setTimeout(() => {
        this.worksComponent.playVita();
      }, 1000);
    }
    // gtag('send', 'view_item', itemId);
  }

  private setIdList(){
    this.idList = [];
    this.idList = this.workContent.map(work => work.id);
  }

  getAllProjectData() {
    // this.projectListDB = this.angularFireDatabase.list('ProjectList');  
    // this._projectService.getAllProjectData('order', 'desc').subscribe((data: any) => {
    //   this.projectList = data.map(e => {
    //     return {  
    //       id: e.payload.doc.id,  
    //       ...e.payload.doc.data()
    //     } as ProjectInfo;
    //   });
    // });  
    
  }

  private initWorkItem(){
    this.workContent = [
      {
        id: 'sedgwick-richardson',
        order: '0044',
        title: `Sedgwick Richardson's website`,
        brand: '',
        location: '',
        technologies: [ 
          'PHP Wordpress 5.9', 
          'Tailwind CSS',
          'Sage Framework',
          'React.js', 
        ],
        challenges: [
          'Variant cursor animations',
          'Animation for scrolling',
          'Wordpress CMS setup',
          'Reveal content function',
          'Responsive homepage title for different devices',
        ],
        description: ``,
        url: 'https://www.sedgwick-richardson.com/',
        isYoutube: false,
        isAnimated: false,
        preview: {
          path: '../../assets/img/sr/sr-01',
          format: 'png'
        },
        images: [
          {
            description: 'Home page with animation',
            border: true,
            src: '../../assets/img/sr/sr-01.png',
            path: '../../assets/img/sr/sr-01',
            format: 'png'
          },
          {
            description: 'Dynamic Image Grid',
            border: true,
            src: '../../assets/img/sr/sr-02.png',
            path: '../../assets/img/sr/sr-02',
            format: 'png'
          },
          {
            description: 'Mobile view',
            border: true,
            src: '../../assets/img/sr/sr-03.png',
            path: '../../assets/img/sr/sr-03',
            format: 'png'
          },
          {
            description: 'demonstrating the menu animation',
            border: true,
            src: '../../assets/img/sr/sr-menu.gif',
            path: '../../assets/img/sr/sr-menu',
            format: 'gif'
          },
        ]
      },
      {
        id: 'si-tracs',
        order: '0043',
        isComingSoon: true,
        title: `Tracs by SaskTel International`,
        brand: '',
        location: 'Saskatchewan, Canada (Remote from Toronto)',
        technologies: [ 
          'Angular 14-16', 
          'Bootstrap 5',
          'Jasmine Unit Test',
          'Java jboss', 
          'Maven(mvn)',
        ],
        challenges: [
          'Support EN/ES/FR',
          'Revamp deprecated parts which are built by Angular 2',
          'Connecting a Java Backend',
          'Requested to export as package (including Frontend and Backend) for different clients',
          'Increase the unit test coverage, from 40% increased to 9x%',
        ],
        description: `Tracs is a web system for Telecom call center staff and technicians. 
        To record the job of repairing network utilities, including the job status, information and emergency level.
        The call center staff will create the task when they receive a call that reports there are network utilities 
        not working. Next, they will mark down their emergency level of the damage, other staff/technicians can trace the job from the system. 
        If the damge happens frequently, the system will mark as threshold to increase awareness. 
        `,
        url: '',
        isYoutube: false,
        isAnimated: false,
        images: []
      },
      {
        id: 'pokemon-types-demo',
        order: '0042',
        title: 'Pokemon Types Filter',
        brand: '',
        location: '',
        technologies: ['UI Design'],
        challenges: [
          'Filtering defender/attacker type by 1 or 2 types, list their effectiveness clearly through UI Design'
        ],
        description: `Just for fun, I developed this page to assist with playing Pokemon Arceus, 
        for easier to know the effectiveness of Pokemon type`,
        url: 'https://www.zoeshu-playground.com/pokemon',
        isYoutube: false,
        isAnimated: false,
        preview: {
                path: '../../assets/img/pokemon/desktop',
                format: 'png'
              },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/pokemon/desktop',
            path: '../../assets/img/pokemon/desktop',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/pokemon/desktop-filtered',
            path: '../../assets/img/pokemon/desktop-filtered',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/pokemon/mobile',
            path: '../../assets/img/pokemon/mobile',
            format: 'png'
          },
        ]
      },
      {
        id: 'animal-crossing-recipe',
        order: '0041',
        title: 'Animal Crossing Recipe',
        brand: '',
        location: '',
        technologies: [
          'Firebase',
          'Bootstrap 5'
        ],
        challenges: [
          'Rating receipts by selling price - cost',
          'Filtering receipts by ingredients'
        ],
        description: `Just for fun, I developed this page to assist with playing Animal Crossing`,
        url: 'https://www.zoeshu-playground.com/animal-crossing/cooking',
        isYoutube: false,
        isAnimated: false,
        preview: {
          path: '../../assets/img/animal-crossing/desktop-preview',
          format: 'png'
        },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/animal-crossing/desktop',
            path: '../../assets/img/animal-crossing/desktop',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/animal-crossing/desktop-filtered',
            path: '../../assets/img/animal-crossing/desktop-filtered',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/animal-crossing/mobile',
            path: '../../assets/img/animal-crossing/mobile',
            format: 'png'
          },
        ]
      },
      {
        id: 'playground-v2',
        order: '0040',
        title: 'My Portfolio Website version 2',
        brand: '',
        location: '',
        technologies: [ 
          'Angular 16', 
          'PWA',
          'Bootstrap 5', 
          'Google Analytics',
          'Lazy-loading modules and images',
        ],
        challenges: [
          'Can fit for different devices, responsive',
          'Revamp the UI for showing over 30 projects',
          'Showing projects but not lengthy and tedious',
          'Scrolling effects for project preview, not possible in using background-position:fixed due, so using js to fix', 
          'Including lots of images of projects, need to optimize the loading speed.'
        ],
        description: `Here is the UI desgin of this website. 
                      One page design makes it easily to browse, and fits to multiple types of devices. 
                      There are over 30 projects to show, 
                      visitors can preview each projects in the home page. 
                      Using slides for the project details can make it easy to browse each project, in stead of scrolling page. 
                      `,
        url: '',
        isYoutube: false,
        isAnimated: false,
        preview: {
          path: '../../assets/img/playground/v2_dt_00',
          format: 'webp'
        },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/playground/v2_dt_00.png',
            path: '../../assets/img/playground/v2_dt_00',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/playground/v2_dt_01.png',
            path: '../../assets/img/playground/v2_dt_01',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/playground/v2_dt_02.png',
            path: '../../assets/img/playground/v2_dt_02',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/playground/v2_mobile_00.png',
            path: '../../assets/img/playground/v2_mobile_00',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/playground/v2_mobile_01.png',
            path: '../../assets/img/playground/v2_mobile_01',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/playground/v2_mobile_02.png',
            path: '../../assets/img/playground/v2_mobile_02',
            format: 'png'
          },
        ]
      },
      {
        id: 'playground',
        order: '0039',
        title: 'My Portfolio Website',
        brand: '',
        location: '',
        technologies: [ 
          'Angular 9', 
          'Bootstrap 3', 
          'Google Analytics' 
        ],
        challenges: [
          'Can fit for different devices, and responsive',
          'Use contrast color to make it eye-catching',
          'Appropriate white space to balance the contrast color',
          'Changing theme color for every visit',
          'Showing over 10 projects'
        ],
        description: `The UI desgin of this website. Using strong logo, contrast colors of UI and animation to make the website memorable, with 90's style box shadow. 
                      Easy to browse all the projects with one page design, and fits to multiple types of devices. 
                      There are over 10 projects to show, using slides can make it easy to browse each project, in stead of scrolling page. 
                      Using Roboto Fonts to display description of works, be more readable.`,
        url: '',
        isYoutube: false,
        isAnimated: false,
        preview: {
          path: '../../assets/img/playground/blueberry_mobile',
          format: 'webp'
        },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/playground/blueberry_desktop.png',
            path: '../../assets/img/playground/blueberry_desktop',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/playground/orange_desktop.png',
            path: '../../assets/img/playground/orange_desktop',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/playground/cheese_desktop.png',
            path: '../../assets/img/playground/cheese_desktop',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/playground/blueberry_mobile.png',
            path: '../../assets/img/playground/blueberry_mobile',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/playground/orange_mobile.png',
            path: '../../assets/img/playground/orange_mobile',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/playground/cheese_mobile.png',
            path: '../../assets/img/playground/cheese_mobile',
            format: 'png'
          },
        ]
      },
      {
        id: 'demo-form',
        order: '0038',
        title: 'Demo of Angular Form',
        isExtra: true,
        preview: {
          path: '../../assets/img/demo-form',
          format: 'webp'
        },
      },
      {
        id: 'emsd-vp',
        order: '0037',
        isComingSoon: true,
        title: 'EMSD - Vendor Portal',
        brand: 'Electrical and Mechanical Services Department (EMSD)',
        location: 'Hong Kong',
        technologies: [
          'Angular 10',
          'Bootstrap 3',
          'JWT',
          'MongoDB',
          'Micro services',
          'Rabbit MQ',
        ],
        challenges: [],
        description: `Vendor Portal is a system for contract vendor, 
        linked to Job Management System, 
        Job Management System is for in-house used only, 
        Vendor Portal is built for public vendor to manage their job.`,
        url: '',
        isYoutube: false,
        isAnimated: false,
        // preview: {
        //         // path: '../../assets/img/emsd/',
        //         // format: 'webp'
        //       },
        images: [
          // {
          //   description: '',
          //   border: true,
          //   src: '../../assets/img/emsd/.png',
          //   path: '../../assets/img/emsd/',
          //   format: 'png'
          // },
        ]
      },
      // {
      //   id: 'emsd-im',
      //   order: '0036',
      //   isComingSoon: true,
      //   title: 'EMSD - Incident Management',
      //   brand: 'Electrical and Mechanical Services Department (EMSD)',
      //   location: 'Hong Kong',
      //   technologies: [
      //     'Angular 9',
      //     'Bootstrap 3',
      //     'JWT',
      //     'MongoDB',
      //   ],
      //   challenges: [],
      //   description: `Coming Soon`,
      //   url: '',
      //   isYoutube: false,
      //   isAnimated: false,
      //   // preview: {
      //   //         // path: '../../assets/img/emsd/',
      //   //         // format: 'webp'
      //   //       },
      //   images: [
      //     // {
      //     //   description: '',
      //     //   border: true,
      //     //   src: '../../assets/img/emsd/.png',
      //     //   path: '../../assets/img/emsd/',
      //     //   format: 'png'
      //     // },
      //   ]
      // },
      {
        id: 'emsd-cp',
        order: '0035',
        isComingSoon: true,
        title: 'EMSD - Cert Portal',
        brand: 'Electrical and Mechanical Services Department (EMSD)',
        location: 'Hong Kong',
        technologies: [ 
          'Angular 10',
          'Bootstrap 3',
          'JWT',
          'MongoDB',
          'Micro services',
          'Rabbit MQ',
        ],
        challenges: [],
        description: `Cert Portal is for in-house staff to management the certificate of public applicants, 
        they can use excel to batch upload certificate information, 
        and record the expiry date. Department manager can approve and activate certificates through this system. 
        The system can schedule to send email to alert staff if the certificate are going to expired. `,
        url: '',
        isYoutube: false,
        isAnimated: false,
        // preview: {
        //         // path: '../../assets/img/emsd/',
        //         // format: 'webp'
        //       },
        images: [
          // {
          //   description: '',
          //   border: true,
          //   src: '../../assets/img/emsd/.png',
          //   path: '../../assets/img/emsd/',
          //   format: 'png'
          // },
        ]
      },
      {
        id: 'emsd-jm',
        order: '0032',
        title: 'EMSD - Job Management System',
        brand: 'Electrical and Mechanical Services Department (EMSD)',
        location: 'Hong Kong',
        technologies: [
          'Angular 9',
          'Bootstrap 3',
          'JWT',
          'MongoDB',
          'Micro services',
          'Rabbit MQ',
        ],
        challenges: [],
        description: `JM is a web system for call center staff and technicians. 
          They can record the job of repairing public utilities, including the job status, information and technician working hour.
          The call center staff will create the task when they receive a call that reports there are public utilities 
          that were broken, such as street light, air conditioners. Next, they will mark down their emergency level of repairment, 
          the system will automatically schedule the task according to the technician team which is available to work. 
          The team will receive push notification via mobile app, 
          they can mark the job status through the mobile app or web system, 
          and after they finished the repairment, they can also mark the duration of the job for the payment. 
          `,
        url: '',
        isYoutube: false,
        isAnimated: false,
        preview: {
                path: '../../assets/img/emsd/jm-01',
                format: 'webp'
              },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/emsd/jm-01-mobile.png',
            path: '../../assets/img/emsd/jm-01-mobile',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/emsd/jm-01.png',
            path: '../../assets/img/emsd/jm-01',
            format: 'png'
          },
        ]
      },
      {
        id: 'biothem-airlines',
        order: '0031',
        title: 'Biothem - Sense of Travel',
        brand: 'Biotherm Asia Pacific',
        location: 'Haitang Bay International Duty Free Complex, Sanya',
        technologies: [ 'Node.js', 'Socket.io', 'Microphone for detecting steer' ],
        challenges: [],
        description: `Visitors can trigger the animation by virtually steer a paper plane`,
        url: 'https://www.youtube.com/embed/Ck-WHjKx6QQ',
        isYoutube: true,
        isAnimated: false,
        preview: {
                path: '../../assets/img/biothem/biothem_01',
                format: 'webp'
              },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/biothem/biothem_01.png',
            path: '../../assets/img/biothem/biothem_01',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/biothem/biothem_02.png',
            path: '../../assets/img/biothem/biothem_02',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/biothem/biothem_03.png',
            path: '../../assets/img/biothem/biothem_03',
            format: 'png'
          },
        ]
      },
      {
        id: 'lancome-pink',
        order: '0030',
        title: 'Lancôme - Stop & Glow Pink Lounge',
        brand: `Lancôme`,
        location: `Singapore Changi Airport T1`,
        description: 
        `The ‘Stop & Glow Pink Lounge’ offers a relaxing multi-sensory environment 
        where travelling customers can enjoy a relaxing beauty fix before their flight. 
        The core idea is to surprise, to go beyond the traditional outpost concept, 
        and to sell by means of innovative engagements in order to create pioneering experiences in an airport. 
        Travellers can play with the interactive fridge which flashes their names on the transparent screen
        `,
        technologies: [ 'Node.js', 'Transparent Screen'],
        challenges: [
          'CCS Animation match with the background video', 
          'Virtual Keyboard',
          'Color tunning for transparent screen'
        ],
        url: 'https://www.youtube.com/embed/1akgwQbqAAg',
        isYoutube: true,
        isAnimated: false,
        preview: {
          path: '../../assets/img/lancome/lancome_fridge',
          format: 'webp'
        },
        images: [
          {
            description: '',
            src: '../../assets/img/lancome/lancome_booth.jpg',
            path: '../../assets/img/lancome/lancome_booth',
            format: 'jpeg',
            border: true,
          },
          {
            description: '',
            src: '../../assets/img/lancome/lancome_fridge_play.jpg',
            path: '../../assets/img/lancome/lancome_fridge_play',
            format: 'jpeg',
            border: true,
          },
          {
            description: '',
            src: '../../assets/img/lancome/lancome_fridge.jpg',
            path: '../../assets/img/lancome/lancome_fridge',
            format: 'jpeg',
            border: true,
          }
        ]
      },
      {
        id: 'shiseido-utm',
        order: '0029',
        title: 'Shiseido S.E.N.S.E of Beauty',
        brand: 'Shiseido',
        location: 'Singapore - Changi Airport',
        technologies: [ 'Node.js', 'Socket.io', 'Snowshoe.js for e-stamp' ],
        challenges: [],
        description: `
          The Shiseido Sense Beauty Pop-up serves as an outpost that gives travellers a glimpse into Shiseido\’s SENSE 
          multi-sensorial art installation in Jewel Changi Airport. The pop-up is located outside the main Shilla Duty Free store 
          in Terminal 1 and features augmented reality technology that allows travellers to discover Shiseido\’s new Ultimune, 
          Sun Care, and White Lucent ranges in an immersive way.
        `,
        url: '',
        isYoutube: false,
        isAnimated: false,
        preview: {
                path: '../../assets/img/shiseido/ssd-utm-01',
                format: 'webp'
              },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/shiseido/ssd-utm-ipad-03.png',
            path: '../../assets/img/shiseido/ssd-utm-ipad-03',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/shiseido/ssd-utm-ipad-01.png',
            path: '../../assets/img/shiseido/ssd-utm-ipad-01',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/shiseido/ssd-utm-01.png',
            path: '../../assets/img/shiseido/ssd-utm-01',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/shiseido/ssd-utm-02.png',
            path: '../../assets/img/shiseido/ssd-utm-02',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/shiseido/ssd-utm-03.png',
            path: '../../assets/img/shiseido/ssd-utm-03',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/shiseido/ssd-utm-04.png',
            path: '../../assets/img/shiseido/ssd-utm-04',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/shiseido/ssd-utm-05.png',
            path: '../../assets/img/shiseido/ssd-utm-05',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/shiseido/ssd-utm-06.png',
            path: '../../assets/img/shiseido/ssd-utm-06',
            format: 'png'
          },
        ]
      },
      {
        id: 'shiseido-billboard',
        order: '0028',
        title: 'Shiseido - Ginza Tokyo',
        brand: 'Shiseido',
        location: 'Haitang Bay International Duty Free Complex, Sanya',
        technologies: [ 'Node.js', 'Socket.io' ],
        challenges: [
          'Neon style frame & text',
          'CSS Animation for spotlight',
        ],
        description: ``,
        url: 'https://www.youtube.com/embed/QBzS9IlVdrA',
        isYoutube: true,
        isAnimated: false,
        preview: {
          path: '../../assets/img/shiseido/shiseido_billboard',
          format: 'webp'
        },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/shiseido/shiseido_booth2.jpg',
            path: '../../assets/img/shiseido/shiseido_booth2',
            format: 'jpeg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/shiseido/shiseido_booth.jpg',
            path: '../../assets/img/shiseido/shiseido_booth',
            format: 'jpeg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/shiseido/shiseido_billboard.jpg',
            path: '../../assets/img/shiseido/shiseido_billboard',
            format: 'jpeg'
          },
        ]
      },
      {
        id: 'undone',
        order: '0027',
        title: '',
        brand: 'Undone Custom Watches',
        location: 'Hong Kong',
        technologies: [ 'Vue.js', 'Bootstrap' ],
        challenges: [
          'First time to use Vue.js',
          'Apply the selected material on the watch immediatly',
          'Material selection box is using tab & gallery style',
          'Need to handle the layering of different parts of a watch',
          'Upload photo and trimmed on the product',
          'Progress bar control'
        ],
        description: ``,
        url: '',
        isYoutube: false,
        isAnimated: true,
        preview: {
          path: '../../assets/img/undone/undone_full',
          format: 'webp'
        },
        coverImage: '../../assets/img/undone/undone_full',
        images: [
          {
            description: '',
            border: true,
            path: '../../assets/img/undone/undone_1',
          },
          {
            description: '',
            border: true,
            path: '../../assets/img/undone/undone_1_2',
          },
          {
            description: '',
            border: true,
            path: '../../assets/img/undone/undone_2',
          },
          {
            description: '',
            border: true,
            path: '../../assets/img/undone/undone_2_1',
          },
          {
            description: '',
            border: true,
            path: '../../assets/img/undone/undone_3',
          },
          {
            description: '',
            border: true,
            path: '../../assets/img/undone/undone_4',
          },
          {
            description: '',
            border: true,
            path: '../../assets/img/undone/undone_4_1',
          },
          {
            description: '',
            border: true,
            path: '../../assets/img/undone/undone_5',
          },
          {
            description: '',
            border: true,
            path: '../../assets/img/undone/undone_5_1',
          },
          {
            description: '',
            border: true,
            path: '../../assets/img/undone/undone_6',
          },
          {
            description: '',
            border: true,
            path: '../../assets/img/undone/undone_6_1',
          },
          {
            description: '',
            border: true,
            path: '../../assets/img/undone/undone_7',
          },
          {
            description: '',
            border: true,
            path: '../../assets/img/undone/undone_8',
          },
          {
            description: '',
            border: true,
            path: '../../assets/img/undone/undone_8_1',
          },
          {
            description: '',
            border: true,
            path: '../../assets/img/undone/undone_9',
          },
          {
            description: '',
            border: true,
            path: '../../assets/img/undone/undone_10',
          },
          {
            description: '',
            border: true,
            path: '../../assets/img/undone/undone_10_1',
          },
          {
            description: '',
            border: true,
            path: '../../assets/img/undone/undone_11',
          },
          {
            description: '',
            border: true,
            path: '../../assets/img/undone/undone_12',
          },
          {
            description: '',
            border: true,
            path: '../../assets/img/undone/undone_12_1',
          },
          {
            description: '',
            border: true,
            path: '../../assets/img/undone/undone_12_2',
          },
          {
            description: '',
            border: true,
            path: '../../assets/img/undone/undone_12_3',
          },
        ]
      },
      {
        id: 'kaws-holiday',
        order: '0026',
        title: 'Kaws Holiday 2019',
        brand: 'KAWS',
        location: 'Japan',
        technologies: ['HTML', 'Bootstrap', 'AWS S3', 'HTML Email'],
        challenges: [],
        description: ``,
        url: '',
        isYoutube: false,
        isAnimated: false,
        preview: {
                path: '../../assets/img/kaws/kaws_holiday',
                format: 'webp'
              },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/kaws/kawsjp_desktop_01.jpg',
            path: '../../assets/img/kaws/kawsjp_desktop_01',
            format: 'jpg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/kaws/kawsjp_desktop_02.jpg',
            path: '../../assets/img/kaws/kawsjp_desktop_02',
            format: 'jpg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/kaws/kawsjp_desktop_03.jpg',
            path: '../../assets/img/kaws/kawsjp_desktop_03',
            format: 'jpg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/kaws/kawsjp_mobile_01.jpg',
            path: '../../assets/img/kaws/kawsjp_mobile_01',
            format: 'jpg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/kaws/kawsjp_mobile_02.jpg',
            path: '../../assets/img/kaws/kawsjp_mobile_02',
            format: 'jpg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/kaws/kawsjp_mobile_03.jpg',
            path: '../../assets/img/kaws/kawsjp_mobile_03',
            format: 'jpg'
          },
        ]
      },
      {
        id: 'prudential-velodrome',
        order: '0025',
        title: 'Prudential - Virtual Velodrome',
        brand: 'Prudential Hong Kong Limited',
        location: 'Hong Kong Hysan Place',
        technologies: [ 'Node.js', 'Arduino', 'Socket.io' ],
        challenges: [
          'Use Arduino (johnny-five.io) and magnets to collect user riding data from the bicycle',
          'Text animation match with the background video',
        ],
        description: `Virtual Velodrome hosted by Prudential HK.`,
        url: 'https://www.youtube.com/embed/34wAUmQ41mw',
        isYoutube: true,
        isAnimated: false,
        preview: {
          path: '../../assets/img/prudential/prudential',
          format: 'webp'
        },
        images: [
          {
            description: '',
            src: '../../assets/img/prudential/prudential-venue.jpg',
            path: '../../assets/img/prudential/prudential-venue',
            format: 'jpeg',
            border: true,
          },
          {
            description: '',
            src: '../../assets/img/prudential/prudential-board.jpg',
            path: '../../assets/img/prudential/prudential-board',
            format: 'jpeg',
            border: true,
          },
          {
            description: '',
            src: '../../assets/img/prudential/prudential-share.jpg',
            path: '../../assets/img/prudential/prudential-share',
            format: 'jpeg',
            border: true,
          }
        ]
      },
      
      {
        id: 'lancome-firework',
        order: '0024',
        title: 'Lancôme CNY 2019 Wish Big',
        isExtra: true,
        preview: {
          path: '../../assets/img/lancome_firework',
          format: 'webp'
        },
      },
      {
        id: 'kate-vday',
        order: '0023',
        title: 'Kate Tokyo - White Diamond',
        brand: 'Kate Tokyo',
        location: 'Hong Kong Hysan Place',
        technologies: [ 'Node.js' ],
        challenges: [
          'Process the image to outline',
          'Carve the outlined image on chocolate',
          'Virtual Keyboard',
        ],
        description: `Valentine's day event hosted by Kate Tokyo.`,
        url: 'https://www.youtube.com/embed/eiqJM4KOuDw',
        isYoutube: true,
        isAnimated: false,
        preview: {
          path: '../../assets/img/kate/kate_booth2',
          format: 'webp'
        },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/kate/kate_booth2.jpg',
            path: '../../assets/img/kate/kate_booth2',
            format: 'jpeg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/kate/kate_chocolate.jpg',
            path: '../../assets/img/kate/kate_chocolate',
            format: 'jpeg'
          },
        ]
      },
      {
        id: 'hennessy-pool',
        order: '0022',
        title: 'Moët Hennessy APAC - CNY 2019 Pool of Blessing',
        brand: 'Moët Hennessy APAC',
        location: 'T3 Departure Hall, Changi Airport',
        technologies: [ 'Node.js', 'Socket.io', 'jquery.ripples', 'CSS Animation' ],
        challenges: [
          'Use Socket.io to connect iPad and TV, to finish full gameplay',
          'CSS Animation (coin flipping, zoom in to specific zone, random item)',
        ],
        description: `Moet Hennessy TR APAC pop-up at Changi Airport invites travellers to 
          celebrate Chinese New Year and engage in “a joyous reunion” through 
          an interactive digital game “Pool of Blessing” which creates over 5,000 total engagements.`,
        url: 'https://www.youtube.com/embed/Ub1ZiJGfurM', //'https://www.youtube.com/embed/wGHLBvfJvoQ',
        isYoutube: true,
        isAnimated: false,
        preview: {
          path: '../../assets/img/hennessy/hennessy_play',
          format: 'webp'
        },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/hennessy/hennessy_booth.jpg',
            path: '../../assets/img/hennessy/hennessy_booth',
            format: 'jpeg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/hennessy/hennessy_demo.jpg',
            path: '../../assets/img/hennessy/hennessy_demo',
            format: 'jpeg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/hennessy/hennessy_play.png',
            path: '../../assets/img/hennessy/hennessy_play',
            format: 'png'
          }
        ]
      },
      {
        id: 'hennessy-postcard',
        order: '0021',
        title: 'Moët Hennessy APAC - CNY 2019 Pool of Blessing',
        brand: 'Moët Hennessy APAC',
        location: 'T3 Departure Hall, Changi Airport',
        technologies: [ 'Node.js', 'HTML Email' ],
        challenges: [
          'Capture finished postcard', 
          'Re-scalling the last element for preview'
          // 'After user got the item from wishing pool, they can write an e-postcard with the blessing message from the item.',
        ],
        description: `Moet Hennessy TR APAC pop-up at Changi Airport invites travellers to 
          celebrate Chinese New Year and engage in “a joyous reunion” through 
          an interactive digital game “Pool of Blessing” which creates over 5,000 total engagements.`,
        url: '',
        isYoutube: false,
        isAnimated: false,
        preview: {
          path: '../../assets/img/hennessy/hennessy_preview',
          format: 'webp'
        },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/hennessy/hennessy_write.png',
            path: '../../assets/img/hennessy/hennessy_write',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/hennessy/hennessy_preview.jpg',
            path: '../../assets/img/hennessy/hennessy_preview',
            format: 'jpeg'
          }
        ]
      },
      {
        id: 'ifc-xmas-scan',
        order: '0020',
        title: 'Santa Academy 2018',
        brand: 'IFC Mall Hong Kong',
        location: 'IFC Hong Kong',
        technologies: [ 'Node.js', 'Tensorflow.js', 'Socket.io', 'Arduino for light box' ],
        challenges: [
          'Using Tensorflow.js to identify the e-postcard graphic, for player to send the e-postcard by email.',
          'Turn on the light box after postcard scanned'
        ],
        description: `ifc mall wanted to add excitement to their Santa Academy activities. 
          In December 2018 they launched an exciting and expansive experience to visitors. 
          This enabled ifc to stand out from holiday experiences at other malls. As part of the visitor experience, 
          ifc wanted to increase traffic to retail sponsors and holiday attractions around the mall. 
          The user experience needed to support a family participation experience (multi-player) 
          with a web app (no mobile app) in less than 45 days for development, testing and launch.`,
        url: 'https://www.youtube.com/embed/p3zdIQqmEgE',
        isYoutube: true,
        isAnimated: false,
        preview: {
          path: '../../assets/img/ifc/ifc_xmas',
          format: 'webp'
        },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/ifc/ifc_xmas_booth.jpg',
            path: '../../assets/img/ifc/ifc_xmas_booth',
            format: 'jpeg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/ifc/ifc_xmas_card.jpg',
            path: '../../assets/img/ifc/ifc_xmas_card',
            format: 'jpeg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/ifc/ifc_xmas_booth2.jpg',
            path: '../../assets/img/ifc/ifc_xmas_booth2',
            format: 'jpeg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/ifc/ifc_xmas_scan.jpg',
            path: '../../assets/img/ifc/ifc_xmas_scan',
            format: 'jpeg'
          }
        ]
      },
      {
        id: 'ifc-xmas-email',
        order: '0019',
        title: 'Santa Academy 2018',
        brand: 'IFC Mall Hong Kong',
        location: 'IFC Hong Kong',
        technologies: [ 'Node.js', 'Socket.io', 'HTML Email' ],
        challenges: [
          'CSS Animation',
          'Virtual Keyboard',
          'Use Arduino to turn on the light after e-postcard sent',
        ],
        description: `ifc mall wanted to add excitement to their Santa Academy activities. 
          In December 2018 they launched an exciting and expansive experience to visitors. 
          This enabled ifc to stand out from holiday experiences at other malls. As part of the visitor experience, 
          ifc wanted to increase traffic to retail sponsors and holiday attractions around the mall. 
          The user experience needed to support a family participation experience (multi-player) 
          with a web app (no mobile app) in less than 45 days for development, testing and launch.`,
        url: 'https://www.youtube.com/embed/bB15gm98gHM',
        isYoutube: true,
        isAnimated: false,
        preview: {
          path: '../../assets/img/ifc/ifc_xmas_form',
          format: 'webp'
        },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/ifc/ifc_xmas_light.png',
            path: '../../assets/img/ifc/ifc_xmas_light',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/ifc/ifc_xmas_form.jpeg',
            path: '../../assets/img/ifc/ifc_xmas_form',
            format: 'jpeg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/ifc/ifc_xmas_send.png',
            path: '../../assets/img/ifc/ifc_xmas_send',
            format: 'png'
          },
        ]
      },
      {
        id: 'vita-vlt-ad',
        order: '0018',
        title: 'Vita VLT Lemon Tea',
        brand: 'Vita VLT',
        isExtra: true,
        preview: {
          path: '../../assets/img/vita_ad_cover',
          format: 'webp'
        },
      },
      {
        id: 'levis-jordan',
        order: '0017',
        title: 'Levi\'s x Jordan',
        brand: 'Levi\'s',
        location: 'Hong Kong',
        technologies: [
          'Node.js',
          'Socket.io',
          'Raspberry Pi',
          'Thermal Printer',
          'SMS Confirmation'
        ],
        challenges: [
          'Use Raspberry Pi to connect thermal printer, print the confirmation of line up'
        ],
        description: `Levi's x Jordan `,
        url: '',
        isYoutube: false,
        isAnimated: false,
        preview: {
                path: '../../assets/img/levis/levis-jordan',
                format: 'webp'
              },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/levis/levis-jordan-03.jpg',
            path: '../../assets/img/levis/levis-jordan-03',
            format: 'jpg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/levis/levis-jordan.jpg',
            path: '../../assets/img/levis/levis-jordan',
            format: 'jpg'
          },
        ]
      },
      {
        id: 'kaws-lamp',
        order: '0016',
        title: 'Kaws Lamp',
        brand: 'Kaws',
        location: 'Hong Kong',
        technologies: [
          'Node.js',
          'MySQL',
          'AWS S3'
        ],
        challenges: [],
        description: ``,
        url: '',
        isYoutube: false,
        isAnimated: false,
        preview: {
                path: '../../assets/img/kaws/kaws_lamp_web01',
                format: 'webp'
              },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/kaws/kaws_lamp_web01.jpeg',
            path: '../../assets/img/kaws/kaws_lamp_web01',
            format: 'jpeg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/kaws/kaws_lamp_web02.jpeg',
            path: '../../assets/img/kaws/kaws_lamp_web02',
            format: 'jpeg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/kaws/kaws_lamp_web03.jpeg',
            path: '../../assets/img/kaws/kaws_lamp_web03',
            format: 'jpeg'
          },
        ]
      },
      {
        id: 'ifc-passport',
        order: '0015',
        title: 'IFC Passport',
        brand: 'IFC',
        location: 'Hong Kong',
        technologies: [
          'Node.js',
          'MySQL',
          'WeChat API'
        ],
        challenges: [],
        description: `
          This is a catalog of shops for IFC Mall, visitors need to use passcode to activate, 
          open in WeChat app. For browsing the coupons of every shop. 
        `,
        url: '',
        isYoutube: false,
        isAnimated: false,
        preview: {
                path: '../../assets/img/ifc/ifc_passport_01',
                format: 'webp'
              },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/ifc/ifc_passport_01.png',
            path: '../../assets/img/ifc/ifc_passport_01',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/ifc/ifc_passport_02.png',
            path: '../../assets/img/ifc/ifc_passport_02',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/ifc/ifc_passport_03.png',
            path: '../../assets/img/ifc/ifc_passport_03',
            format: 'png'
          },
        ]
      },
      {
        id: 'hysan-vday',
        order: '0014',
        title: 'Hysan Valentine\'s Day',
        brand: 'Hysan',
        location: 'Hong Kong',
        technologies: [
          'Node.js',
          'Socket.io',
          'MySQL',
        ],
        challenges: [],
        description: `
          An event for valentine's day that hosted in Hysan Place, visitors can show their love message on the wall, 
          after $500 purchase. 
        `,
        url: '',
        isYoutube: false,
        isAnimated: false,
        preview: {
                path: '../../assets/img/hysan/hysan-vday-01',
                format: 'webp'
              },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/hysan/hysan-vday-01.png',
            path: '../../assets/img/hysan/hysan-vday-01',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/hysan/hysan-vday-02.png',
            path: '../../assets/img/hysan/hysan-vday-02',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/hysan/hysan-vday-03.png',
            path: '../../assets/img/hysan/hysan-vday-03',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/hysan/hysan-vday-04.png',
            path: '../../assets/img/hysan/hysan-vday-04',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/hysan/hysan-vday05.png',
            path: '../../assets/img/hysan/hysan-vday-05',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/hysan/hysan-vday-project01.jpg',
            path: '../../assets/img/hysan/hysan-vday-project01',
            format: 'jpg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/hysan/hysan-vday-project02.jpg',
            path: '../../assets/img/hysan/hysan-vday-project02',
            format: 'jpg'
          },
        ]
      },
      {
        id: 'levis-cny-2018',
        order: '0013',
        title: 'Levi\'s CNY 2018',
        brand: 'Levi\'s',
        location: 'Hong Kong',
        technologies: [
          'Node.js',
          'gyronorm.js',
          'SMS confirmation'
        ],
        challenges: [
          'When user rotate the device, will go to next page: Use gyronorm to get gyroscope and accelerometer data from browser.',
          'Randomlt draw a prize to user',
          'Using page transition to make the website alive, because the button are not suit to use effect/animation'
        ],
        description: `
          Visitors can make a wish through interacting with the website, and get a prize from Levi\'s Hong Kong.
        `,
        url: '',
        isYoutube: false,
        isAnimated: false,
        preview: {
                path: '../../assets/img/levis/levis-cny-01',
                format: 'webp'
              },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/levis/levis-cny-01.png',
            path: '../../assets/img/levis/levis-cny-01',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/levis/levis-cny-03.jpg',
            path: '../../assets/img/levis/levis-cny-03',
            format: 'jpg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/levis/levis-cny-10.jpg',
            path: '../../assets/img/levis/levis-cny-10',
            format: 'jpg'
          },
        ]
      },
      {
        id: 'city-plaza-xmas',
        order: '0012',
        isComingSoon: true,
        title: 'CityPlaza 2017 X\'mas - Happy Bidding',
        brand: 'CityPlaza',
        location: 'Hong Kong',
        technologies: [
          'Node.js',
          'MySQL',
        ],
        challenges: [],
        description: `Coming Soon`,
        url: '',
        isYoutube: false,
        isAnimated: false,
        // preview: {
        //         // path: '../../assets/img/city-plaza/',
        //         // format: 'webp'
        //       },
        images: [
          // {
          //   description: '',
          //   border: true,
          //   src: '../../assets/img/city-plaza/.png',
          //   path: '../../assets/img/city-plaza/',
          //   format: 'png'
          // },
        ]
      },
      {
        id: 'hysan-xmas-2017',
        order: '0011',
        title: 'Hysan Xmas 2017',
        brand: 'Hysan',
        location: 'Hong Kong',
        technologies: [
          'Node.js',
          'firebase',
        ],
        // challenges: [],
        description: `
          Visitor can purchase the customized baubles online, and check the location of baubles on the website
        `,
        url: '',
        isYoutube: false,
        isAnimated: false,
        preview: {
                path: '../../assets/img/hysan/hysan-xmas-01',
                format: 'webp'
              },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/hysan/hysan-xmas-deco-01.png',
            path: '../../assets/img/hysan/hysan-xmas-deco-01',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/hysan/hysan-xmas-deco-02.png',
            path: '../../assets/img/hysan/hysan-xmas-deco-02',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/hysan/hysan-xmas-01.jpg',
            path: '../../assets/img/hysan/hysan-xmas-01',
            format: 'jpg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/hysan/hysan-xmas-02.png',
            path: '../../assets/img/hysan/hysan-xmas-02',
            format: 'png'
          },
        ]
      },
      {
        id: 'telford-play',
        order: '0010',
        title: 'Telford',
        brand: 'Telford',
        location: 'Hong Kong',
        technologies: [
          'HTML, Plain JavaScript for the frontend',
          'CSS Animation',
          'Parallax.js for elements layering, and reacts to the orientation of device'
        ],
        challenges: [
          'Animation of the capsule is not easy to look smooth, and because it is in circle shape.',
        ],
        description: ``,
        url: '',
        isYoutube: false,
        isAnimated: false,
        preview: {
                path: '../../assets/img/telford/telford-play-01',
                format: 'webp'
              },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/telford/telford-play-01.png',
            path: '../../assets/img/telford/telford-play-01',
            format: 'png'
          },
        ]
      },
      {
        id: 'telford-vote',
        order: '0009',
        title: 'Telford Vote',
        brand: 'Telford',
        location: 'Hong Kong',
        technologies: [
          'PHP',
          'Framework: Symphony',
          'MySQL',
          'SMS verification',
          'Mobile & Kiosk Display'
        ],
        challenges: [],
        description: ``,
        url: '',
        isYoutube: false,
        isAnimated: false,
        preview: {
                path: '../../assets/img/telford/telford-vote-02',
                format: 'webp'
              },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/telford/telford-vote-01.jpg',
            path: '../../assets/img/telford/telford-vote-01',
            format: 'jpg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/telford/telford-vote-02.jpg',
            path: '../../assets/img/telford/telford-vote-02',
            format: 'jpg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/telford/telford-vote-03.jpg',
            path: '../../assets/img/telford/telford-vote-03',
            format: 'jpg'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/telford/telford-vote-05.png',
            path: '../../assets/img/telford/telford-vote-05',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/telford/telford-vote-display-01.jpg',
            path: '../../assets/img/telford/telford-vote-display-01',
            format: 'jpg'
          },
        ]
      },
      // {
      //   id: 'sasa-beauty',
      //   order: '0008',
      //   isComingSoon: true,
      //   title: 'SaSa ',
      //   brand: 'SaSa',
      //   location: 'Hong Kong',
      //   technologies: [
      //     'PHP',
      //     'Framework: Symphony',
      //     'MySQL',
      //   ],
      //   challenges: [],
      //   description: `Coming Soon`,
      //   url: '',
      //   isYoutube: false,
      //   isAnimated: false,
      //   // preview: {
      //   //         // path: '../../assets/img/sasa/',
      //   //         // format: 'webp'
      //   //       },
      //   images: [
      //     // {
      //     //   description: '',
      //     //   border: true,
      //     //   src: '../../assets/img/sasa/.png',
      //     //   path: '../../assets/img/sasa/',
      //     //   format: 'png'
      //     // },
      //   ]
      // },
      {
        id: 'yyj-danisa-coookies',
        order: '0007',
        isComingSoon: true,
        title: 'Danisa Cookies',
        brand: 'You Yi Jia (Hong Kong) Food Trading Limited',
        location: 'Hong Kong',
        technologies: [
          'PHP',
          'Framework: Symphony',
          'MySQL',
        ],
        challenges: [],
        description: ``,
        url: '',
        isYoutube: false,
        isAnimated: false,
        // preview: {
        //         // path: '../../assets/img/yyj/',
        //         // format: 'webp'
        //       },
        images: [
          // {
          //   description: '',
          //   border: true,
          //   src: '../../assets/img/yyj/.png',
          //   path: '../../assets/img/yyj/',
          //   format: 'png'
          // },
        ]
      },
      {
        id: 'parsons-music',
        order: '0006',
        title: 'Parsons',
        brand: 'Parsons',
        location: 'Hong Kong',
        technologies: [
          'PHP',
          'Framework: Symphony',
          'MySQL',
        ],
        challenges: [
          'Need to fit the web content into a irregular content wrap (e.g. Grand Piano)',
          'Use frame and play button for embedd video',
          'CSS Animation for decoration'
        ],
        description: ``,
        url: '',
        isYoutube: false,
        isAnimated: false,
        preview: {
                path: '../../assets/img/parsons/parsons01',
                format: 'webp'
              },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/parsons/parsons01.png',
            path: '../../assets/img/parsons/parsons01',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/parsons/parsons02.png',
            path: '../../assets/img/parsons/parsons02',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/parsons/parsons06.png',
            path: '../../assets/img/parsons/parsons06',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/parsons/parsons11.png',
            path: '../../assets/img/parsons/parsons11',
            format: 'png'
          },
        ]
      },
      {
        id: 'zhao-da',
        order: '0005',
        title: 'Zhao Da',
        brand: 'Zhao Da',
        location: 'Hong Kong',
        technologies: [
          'PHP',
          'Framework: Symphony',
          'MySQL'
        ],
        challenges: [],
        description: ``,
        url: '',
        isYoutube: false,
        isAnimated: false,
        preview: {
                path: '../../assets/img/zhaoda/zhaoda01',
                format: 'webp'
              },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/zhaoda/zhaoda01.png',
            path: '../../assets/img/zhaoda/zhaoda01',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/zhaoda/zhaoda07.png',
            path: '../../assets/img/zhaoda/zhaoda07',
            format: 'png'
          },
        ]
      },
      {
        id: 'msl',
        order: '0004',
        title: 'MSL',
        brand: 'MSL',
        location: 'Hong Kong',
        technologies: [
          'PHP',
          'Framework: Symphony',
          'MySQL'
        ],
        challenges: [],
        description: ``,
        url: '',
        isYoutube: false,
        isAnimated: false,
        preview: {
                path: '../../assets/img/msl/msl01_mobile',
                format: 'webp'
              },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/msl/msl01_mobile.png',
            path: '../../assets/img/msl/msl01_mobile',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/msl/msl01.png',
            path: '../../assets/img/msl/msl01',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/msl/msl04.png',
            path: '../../assets/img/msl/msl04',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/msl/msl06.png',
            path: '../../assets/img/msl/msl06',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/msl/msl11.png',
            path: '../../assets/img/msl/msl11',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/msl/msl14.png',
            path: '../../assets/img/msl/msl14',
            format: 'png'
          },
        ]
      },
      {
        id: 'crg',
        order: '0003',
        // isComingSoon: true,
        title: 'CRG - Real Estate',
        brand: 'CRG',
        location: 'Global',
        technologies: [
          'PHP',
          'Framework: Laravel',
          'MySQL'
        ],
        challenges: [],
        description: `Coming Soon`,
        url: '',
        isYoutube: false,
        isAnimated: false,
        preview: {
                path: '../../assets/img/crg/crg',
                format: 'webp'
              },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/crg/crg.png',
            path: '../../assets/img/crg/crg',
            format: 'png'
          },
        ]
      },
      {
        id: 'ap-assets',
        order: '0002',
        title: 'AP Assets - Real Estate',
        brand: 'AP Assets',
        location: 'Global',
        technologies: [
          'PHP',
          'Framework: Laravel',
          'MySQL',
          'AOS',
        ],
        challenges: [],
        description: ``,
        url: '',
        isYoutube: false,
        isAnimated: false,
        preview: {
                path: '../../assets/img/apassets/apassets01',
                format: 'webp'
              },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/apassets/apassets01.png',
            path: '../../assets/img/apassets/apassets01',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/apassets/apassets01_mobile.png',
            path: '../../assets/img/apassets/apassets01_mobile',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/apassets/apassets00.png',
            path: '../../assets/img/apassets/apassets00',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/apassets/apassets09.png',
            path: '../../assets/img/apassets/apassets09',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/apassets/apassets09_mobile.png',
            path: '../../assets/img/apassets/apassets09_mobile',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/apassets/apassets11.png',
            path: '../../assets/img/apassets/apassets11',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/apassets/apassets13.png',
            path: '../../assets/img/apassets/apassets13',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/apassets/apassets13_mobile.png',
            path: '../../assets/img/apassets/apassets13_mobile',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/apassets/apassets16.png',
            path: '../../assets/img/apassets/apassets16',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/apassets/apassets16_mobile.png',
            path: '../../assets/img/apassets/apassets16_mobile',
            format: 'png'
          },
        ]
      },
      {
        id: 'beak',
        order: '0001',
        title: 'Beak',
        brand: 'Beak',
        location: 'Hong Kong',
        technologies: [
          'PHP',
          'Framework: Symphony',
          'MySQL',
          'AOS',
          'AFrame.io: 360° Image'
        ],
        challenges: [],
        description: ``,
        url: '',
        isYoutube: false,
        isAnimated: false,
        preview: {
                path: '../../assets/img/beak/beak01',
                format: 'webp'
              },
        images: [
          {
            description: '',
            border: true,
            src: '../../assets/img/beak/beak01.png',
            path: '../../assets/img/beak/beak01',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/beak/beak02.png',
            path: '../../assets/img/beak/beak02',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/beak/beak03.png',
            path: '../../assets/img/beak/beak03',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/beak/beak04.png',
            path: '../../assets/img/beak/beak04',
            format: 'png'
          },
          {
            description: '',
            border: true,
            src: '../../assets/img/beak/beak05.png',
            path: '../../assets/img/beak/beak05',
            format: 'png'
          },
        ]
      },
    ];

  }
}

/* format
{
  id: '',
  order: 0000,
  title: '',
  brand: '',
  location: '',
  technologies: [],
  challenges: [],
  description: ``,
  url: '',
  isYoutube: false,
  isAnimated: false,
  preview: {
          path: '../../assets/img/',
          format: 'webp'
        },
  images: [
    {
      description: '',
      border: true,
      src: '../../assets/img/',
      path: '../../assets/img/',
      format: 'png'
    },
  ]
}
*/